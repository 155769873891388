@use 'sass:map';
@use 'sass:color';

.breakout {
  width: 100%;
  &,
  &--medium,
  &-container-alternate &:nth-child(even) {
    background-color: $body-background;
  }
  &--light,
  &-container-alternate &:nth-child(odd) {
    background: $white;
  }
  &--border-bottom,
  &--border-top {
    position: relative;
    &::after {
      background: $gainsboro;
      content: '';
      display: block;
      height: 1px;
      left: 0;
      position: absolute;
      width: 100%;
    }
  }
  &--border-bottom {
    &::after {
      bottom: 0;
    }
  }
  &--border-top {
    &::after {
      top: 0;
    }
  }

  &-container-alternate &:nth-child(odd) {
    .row--flex {
      @include breakpoint(medium) {
        flex-direction: row-reverse;
      }
    }
  }
  &-container-alternate &:nth-child(even) {
    .button {
      background-color: $secondary-color;
      &:hover {
        background-color: color.scale($secondary-color, $lightness: $button-background-hover-lightness);
      }
    }
  }
}

h2:not([class]),
[class*='heading--jumbo'],
a:not(.button) {
  .breakout-container-alternate .breakout:nth-child(odd) &,
  .breakout--primary & {
    color: $primary-color;
  }
  .breakout-container-alternate .breakout:nth-child(even) &,
  .breakout--secondary & {
    color: color.scale($secondary-color, $lightness: -10%);
  }
}

.breakout-container-alternate .breakout:nth-child(even) a,
.breakout--secondary a {
  &:not(.button):hover,
  &:not(.button):focus {
    color: color.scale($secondary-color, $lightness: -25%);
  }
}

.breakout--cta {
  background-color: color.scale($secondary-color, $lightness: -30%);
  [class*='heading--h'],
  p {
    color: $white;
  }
}

@include breakpoint(medium) {
  .breakout {
    &__text-wrap,
    &__text-wrap.text-center {
      .feature__intro > p,
      > p {
        margin-left: auto;
        margin-right: auto;
        max-width: 600px;
      }
      p {
        font-size: 1.1rem;
      }
      h2 {
        font-size: 2.2rem;
      }
    }
  }
}
