.dual-cta {
  @include breakpoint(small only) {
    .column + .column {
      border-top: 1px solid $gainsboro;
    }
  }

  @include breakpoint(medium) {

    .column + .column {
      border-left: 1px solid $gainsboro;
    }

    &__section {
      align-items: center;
      display: flex;
      flex-flow: column nowrap;
      justify-content: space-between;
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }

  @include breakpoint(large) {
    &__section {
      padding-left: 4rem;
      padding-right: 4rem;
    }
  }
}
