$speech-bubble-pointer-offset: 4rem;
$speech-bubble-offset: 1rem;

/* stylelint-disable declaration-no-important */
.speech-bubble {
  display: inline-block;
  margin: 0 0 1rem;
  position: relative;
  z-index: 20;
  &.left {
    margin-left: 1rem;
  }
  &.right {
    margin-right: 1rem;
  }
  &::before {
    background: url('../images/deadlyduck/illustrations/speech-pointer.svg');
    background-size: 33px 23px;
    bottom: -22px;
    content: '';
    height: 23px;
    left: $speech-bubble-offset;
    position: absolute;
    width: 33px;
    z-index: 0;
  }
  &--left-pointer {
    &::before {
      // this is the default
    }
  }
  &--right-pointer {
    &::before {
      left: auto;
      right: $speech-bubble-offset;
      transform: scaleX(-1);
    }
  }
  &__content {
    background-color: $primary-color;
    border: 0;
    border-radius: 25px !important;
    color: $white !important;
    display: block;
    padding: 1rem 2rem;
    position: relative;
    text-align: center;
    z-index: 3;
    .button {
      border: 2px solid $white;
      &:not(:hover) {
        background-color: $primary-color;
      }
    }
    form .button {
      border: 0;
    }
  }
  &--large {
    .speech-bubble__content {
      padding: 2rem;
    }
  }
  &--small {
    .speech-bubble__content {
      font-size: .9rem;
      min-width: 0;
      padding: .5rem 1rem;
      white-space: nowrap;
    }
    &::before {
      bottom: -10px;
    }
  }
  &--light {
    &::before {
      background-image: url('../images/deadlyduck/illustrations/speech-pointer--light.svg');
    }
    .speech-bubble__content {
      background-color: $white;
      color: $body-font-color !important;
      h1,
      h2,
      h3,
      p {
        color: $body-font-color;
      }
      p a {
        color: $anchor-color;
      }
    }
  }
  &--secondary {
    &::before {
      background-image: url('../images/deadlyduck/illustrations/speech-pointer--secondary.svg');
    }
    .speech-bubble__content {
      background-color: $secondary-color;
    }
  }
  &--alert {
    &::before {
      background-image: url('../images/deadlyduck/illustrations/speech-pointer--warning.svg');
    }
    .speech-bubble__content {
      background-color: $alert-color;
    }
  }
  &:not(.speech-bubble--light) * {
    &,
    &:hover,
    &:focus {
      color: $white;
    }
  }
}

@include breakpoint(medium) {
  .speech-bubble {
    &.left {
      margin-left: 2rem;
    }
    &.right {
      margin-right: 2rem;
    }
    &__content {
      min-width: 180px;
    }
  }
}

/* stylelint-enable */
