.review-badge {
  display: inline-block;
  width: 100%;
  &,
  &:hover,
  &:focus {
    color: $body-font-color;
  }
  &__list {
    margin-bottom: 0;
    padding: 0 1rem;
    text-align: center;
    &-item {
      list-style: none;
      + .review-badge__list-item {
        border-top: 1px solid $gainsboro;
        margin-top: 1rem;
        padding-top: 1rem;
      }
    }
  }
  &__logo {
    margin-bottom: 1rem;
    max-width: 180px;
  }
  &__score {
    color: $header-color;
    font-size: 1.1rem;
  }
  p {
    max-width: none;
  }
}

.feature__list + .review-badge__list {
  margin-top: 2rem;
}

@include breakpoint(medium) {
  .review-badge__list {
    display: flex;
    &-item {
      align-items: center;
      display: flex;
      flex-flow: column nowrap;
      justify-content: flex-end;
      + .review-badge__list-item {
        border-left: 1px solid $gainsboro;
        border-top: 0;
        margin: 0;
        padding: 0;
      }
    }
  }
}
