.skip-link {
  @include element-invisible($enforce: false);
  background: $white;
  border-radius: 0 0 $card-border-radius $card-border-radius;
  box-shadow: $card-1-shadow;
  display: inline-block;
  left: 50%;
  padding: .25rem .5rem;
  position: absolute;
  transform: translateX(-50%);
  z-index: 20;
  &:active,
  &:focus {
    @include element-invisible-off($enforce: false);
    position: absolute;
  }
}
