/* stylelint-disable selector-max-type */
.feature,
.feature__wrap > ul > li,
.feature__list li {
  text-align: center;
  .mdi {
    color: $primary-color;
    display: inline-block;
    margin-bottom: .5rem;
    &::before {
      font-size: 32px;
      line-height: 1;
    }
  }
  h3,
  > strong {
    color: $header-color;
    display: block;
    font-size: rem-calc(map-deep-get($header-styles, 'small', 'h3', 'font-size'));
  }
  p {
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.feature__wrap > ul,
.feature__list {
  margin: 2rem 0 1rem;
  > li {
    border: 1px solid $gainsboro;
    flex-grow: 1;
    list-style: none;
    margin: 0 0 -1px;
    padding: 1rem;
    &::before {
      display: none;
    }
  }
  p {
    font-weight: normal;
  }
}

@include breakpoint(medium) {
  .feature__wrap > ul,
  .feature__list {
    align-items: stretch;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    margin-top: 3rem;
    > li {
      flex-basis: 33.3%;
      flex-grow: 1;
      margin: 0 -1px -1px 0;
      max-width: 33.3%;
      padding: 2rem;
      width: 33.3%;
      > strong {
        font-size: rem-calc(map-deep-get($header-styles, 'medium', 'h3', 'font-size'));
      }
    }
  }
}
/* stylelint-enable */
