.menu {
  @include clearfix;
  margin: 0;
  &__icon {
    flex-shrink: 0;
    margin-right: .5rem;
  }
  li {
    list-style: none;
  }
  a,
  button:not(.button) {
    color: $light-font-color;
    &:hover,
    &:focus {
      color: $secondary-color;
    }
  }
  .hier > a,
  .active a,
  .hier > button:not(.button),
  .active > button:not(.button) {
    color: $secondary-color;
    font-weight: $global-weight-bold;
  }
  &.icons a,
  &.icons button:not(.button) {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  &.horizontal {
    li {
      &:first-child a,
      &:first-child button:not(.button) {
        padding-left: 0;
      }
      &:last-child a,
      &:last-child button:not(.button) {
        padding-right: 0;
      }
    }
    .level2 {
      margin: 0;
      > li {
        float: none;
      }
    }
  }
  &.level1 {
    > li {
      margin-bottom: .5rem;
      > a,
      > button:not(.button) {
        font-weight: $global-weight-medium;
      }
    }
  }
  .level2 {
    a,
    button:not(.button) {
      font-size: rem-calc(13);
    }
  }
  &--primary {
    a,
    button:not(.button) {
      color: $secondary-color;
      &:hover,
      &:focus {
        color: $primary-color;
      }
    }
  }
}

@include breakpoint(small only) {
  .menu {
    &.horizontal {
      li {
        display: block;
        padding: 0;
      }
    }
  }
}
