.faqcontainer .navigation {
  padding: 0;
  li + li {
    border: 0;
  }
  .level1 {
    display: block;
    margin: 0 -1rem;
    > li {
      margin: 0;
      padding: 1.5rem;
      > a {
        &::before {
          left: auto;
          margin-top: -12px;
          right: 2rem;
          top: 50%;
        }
      }
    }
    .level2 {
      li + li {
        border: 0;
        margin-top: .25rem;
      }
    }
  }
}

@include breakpoint(medium) {
  .faqcontainer .navigation {
    .level1 {
      column-count: 2;
      column-gap: 0;
      > li {
        display: inline-block;
        width: 100%;
      }
    }
  }
}
