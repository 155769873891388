/*! rtl:begin:ignore */
.illustration--to-the-top {
  &__mountainflag {
    transform: translate(267px, 21px);
  }
  &__flying-knowly {
    animation: animation-mountain-flying-knowly linear 1s infinite;
    transform: rotate(15deg) translate(100px, 0);
  }
}

/*! rtl:end:ignore */
