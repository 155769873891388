@use 'sass:color';

$panel-primary-anchor-color: $primary-color;
$panel-secondary-anchor-color: color.scale($secondary-color, $lightness: -5%);
$panel-success-anchor-color: color.scale($success-color, $lightness: -5%);
$panel-info-anchor-color: color.scale($info-color, $lightness: -10%);
$panel-alert-anchor-color: color.scale($alert-color, $lightness: -25%);
$panel-warning-anchor-color: color.scale($warning-color, $lightness: -32%);
$panel-gray-anchor-color: $primary-color;

.panel {
  @include clearfix;
  border-radius: $global-radius;
  clear: both;
  display: block;
  margin: 0 0 ($column-gutter * .5);
  overflow: hidden;
  padding: $column-gutter * .5;
  position: relative;
  &--primary { @include panel($primary-color, $panel-primary-anchor-color); }
  &--secondary { @include panel($secondary-color, $panel-secondary-anchor-color); }
  &--success { @include panel($success-color, $panel-success-anchor-color); }
  &--info { @include panel($info-color, $panel-info-anchor-color); }
  &--alert { @include panel($alert-color, $panel-alert-anchor-color); }
  &--warning { @include panel($warning-color, $panel-warning-anchor-color); }
  &--gray { @include panel($jumbo, $panel-gray-anchor-color); }
  &--collapse {
    align-items: flex-start;
    * {
      margin-bottom: 0;
    }
  }
  .button {
    color: $white;
    position: relative;
    z-index: 1;
  }
  .column {
    + .column {
      margin-top: 1rem;
    }
    ul,
    p {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

@include breakpoint(medium) {
  .panel {
    margin: 0 0 $column-gutter;
    padding: $column-gutter;
    .row {
      align-items: center;
      display: flex;
      .column {
        margin: 0;
      }
    }
  }
}
