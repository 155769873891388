/*! rtl:begin:ignore */
.illustration--help-page {
  &__knowly-face {
    animation: animation-nodding linear 8s infinite;
  }
  &__question-bubble {
    animation: animation-fade-in linear .3s forwards;
    animation-delay: 1s;
    opacity: 0;
  }
  &__question-mark {
    animation: animation-fade-in linear .3s forwards;
    animation-delay: 1.33s;
    opacity: 0;
  }
  &__dots-bubble {
    animation: animation-fade-in linear .3s forwards;
    animation-delay: 2s;
    opacity: 0;
  }
  &__dots {
    > path:nth-child(1) {
      animation: animation-fade-in linear .3s forwards;
      animation-delay: 2.33s;
      opacity: 0;
    }
    > path:nth-child(2) {
      animation: animation-fade-in linear .3s forwards;
      animation-delay: 2.66s;
      opacity: 0;
    }
    > path:nth-child(3) {
      animation: animation-fade-in linear .3s forwards;
      animation-delay: 3s;
      opacity: 0;
    }
  }
}

/*! rtl:end:ignore */
