/*! rtl:begin:ignore */
@keyframes animation-float-fall {
  0% {
    transform: rotate(0);
  }
  15% {
    transform: rotate(40deg);
  }
  30% {
    transform: rotate(25deg);
  }
  40% {
    transform: rotate(35deg) skew(0);
  }
  80% {
    transform: rotate(34deg) translate3d(203px, 277px, 0) skew(-40deg, 0);
  }
  100% {
    transform: rotate(37deg) translate3d(203px, 277px, 0) skew(-40deg, 0);
  }
}

/*! rtl:end:ignore */
