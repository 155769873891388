// we want to have control over which fieldsets have styling
// so reset normal styling and put fieldset styling on a class
fieldset {
  margin: 0;
  padding: 0;
}

.fieldset {
  margin: 1rem 0;
  max-width: $fieldset-max-width;
  padding: $fieldset-padding;
  > *:last-child {
    margin-bottom: 0;
  }
  &--fullwidth {
    max-width: none;
  }
  + .fieldset {
    margin-top: 2rem;
  }
  &--nomargin {
    margin: 0;
  }
}

@include breakpoint(medium) {
  .fieldset {
    margin: $fieldset-margin;
    + .fieldset {
      margin-top: 3rem;
    }
    &--nomargin {
      margin: 0;
    }
  }
}
