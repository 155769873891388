@keyframes animation-nodding {
  0% {
    transform: translate(0, 0);
  }
  46% {
    transform: translate(0, 0);
  }
  48% {
    transform: translate(0, 3px);
  }
  50% {
    transform: translate(0, 0);
  }
  52% {
    transform: translate(0, 3px);
  }
  54% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}
