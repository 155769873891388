.label {
  font-weight: $global-weight-normal;
  &.primary { @include label(primary); }
  &.secondary { @include label(secondary); }
  &.success { @include label(success); }
  &.alert { @include label(alert); }
  &.warning { @include label(warning); }
  &.info { @include label(info); }
  &--large {
    font-size: .9rem;
  }
}
