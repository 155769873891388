@use 'sass:color';
@use 'sass:map';
@import '~@mdi/font/scss/variables',
  '~@mdi/font/scss/functions';

@mixin materialiconize($name, $size: 24px) {
  content: mdi($name);
  display: inline-block;
  font-family: $icon-font-family;
  font-size: rem-calc($size);
  line-height: 1;
  vertical-align: middle;
}

@mixin panel($color, $anchor-color, $light-theme: true) {
  $panel-background-color: color.scale($color, $lightness: 92%);
  $panel-border-color: color.scale($color, $lightness: 80%);
  $panel-anchor-color-hover-factor: -10%;

  @if $light-theme == false {
    $panel-background-color: color.adjust(color.scale($color, $lightness: -70%), $saturation: -50%);
    $panel-border-color: color.adjust(color.scale($color, $lightness: -63%), $saturation: -50%);
    $panel-anchor-color-hover-factor: 10%;
  }
  background-color: $panel-background-color;
  border: 1px solid $panel-border-color;
  a:not([class]),
  .button.link {
    color: $anchor-color;
    &:hover,
    &:focus {
      color: color.scale($anchor-color, $lightness: $panel-anchor-color-hover-factor);
      text-decoration: underline;
      transition: color .2s ease;
    }
  }
}

@mixin label($state, $light-theme: true) {
  @if $light-theme {
    color: color.scale(map.get($foundation-palette, $state), $lightness: -50%);
  } @else {
    color: map.get($foundation-palette-lightened, $state);
  }
}

@mixin beta-label {
  background: $info-color;
  border-radius: $global-radius;
  color: $white;
  font-size: $label-font-size;
  font-weight: $global-weight-bold;
  margin-left: .333rem;
  padding: .25rem .5rem;
  text-transform: uppercase;
}

@mixin loader-base($color, $size) {
  animation: loader 400ms infinite linear;
  border-bottom: 2px solid $color;
  border-left: 2px solid transparent;
  border-radius: 50%;
  border-right: 2px solid $color;
  border-top: 2px solid $color;
  content: ' ';
  display: block;
  font-size: 0;
  height: $size;
  left: 50%;
  margin: (-$size * .5) 0 0 (-$size * .5);
  position: absolute;
  top: 50%;
  width: $size;
  z-index: 99;

  @media (prefers-reduced-motion: reduce) {
    animation-duration: 1000ms !important; /* stylelint-disable-line declaration-no-important */
  }
}

@mixin artifical-keyboard-focus {
  box-shadow: 0 0 2px 1px $primary-color;
  [data-whatinput='mouse'] & {
    box-shadow: none;
  }
}
