/*! rtl:begin:ignore */
.illustration--colleagues {
  &__knowly--left .illustration--colleagues__knowly-eyes {
    animation: animation-colleagues-eyes-looking-right linear 10s infinite;
    animation-delay: 2.5s;
  }
  &__knowly--right .illustration--colleagues__knowly-eyes {
    animation: animation-colleagues-eyes-looking-left linear 10s infinite;
  }
}

/*! rtl:end:ignore */
