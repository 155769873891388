.page--customers {
  .card__header {
    background: $white;
    justify-content: center;
    img {
      height: 100%;
      object-fit: contain;
      width: 100%;
    }
  }
  .jumbotron__section .row > .column:last-child {
    @include breakpoint(medium) {
      justify-content: center;
    }

    @include breakpoint(small only) {
      padding-bottom: 2rem;
    }
  }
}

.customer-logo {
  background: url('../images/deadlyduck/illustrations/knowly--holding-sign.svg') no-repeat center center;
  padding: 28% 18% 17% 14%;
  &::after,
  &::before {
    background: $oil;
    bottom: 0;
    content: '';
    display: block;
    height: 54%;
    left: 20%;
    position: absolute;
    width: 2%;
    z-index: -1;
  }
  &::after {
    left: auto;
    right: 24%;
  }
  &__inner {
    padding-bottom: 47.5%;
    position: relative;
  }
  &__image {
    height: 100%;
    left: 0;
    object-fit: contain;
    position: absolute;
    top: 0;
    width: 100%;
  }
}
