/*! rtl:begin:ignore */

@keyframes gentle-swing {
  0% {
    transform: rotate(5deg);
  }
  50% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(5deg);
  }
}

/*! rtl:begin:ignore */
