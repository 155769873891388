$register-cta-knowly-offset: 0%;
$register-cta-knowly-offset-medium: 5%;
$register-cta-knowly-offset-large: 38%;
$register-cta-mountains-offset: 10%;
$register-cta-mountains-offset-medium: 30%;

.register-cta {
  min-height: 0;
  padding: 3rem 0 8rem;
  position: relative;
  transform: none;
  .column {
    z-index: 2;
  }
  .clouds {
    top: 4rem;
  }
  .mountains {
    bottom: 0;
    left: $register-cta-mountains-offset;
    min-width: 280px;
    opacity: .3;
    position: absolute;
    width: 40%;
  }
  .knowly {
    bottom: 0;
    left: $register-cta-knowly-offset;
    position: absolute;
    width: 200px;
    z-index: 2;
  }
  .speech-bubble {
    &::before {
      z-index: 4;
    }
    .speech-bubble__content {
      box-shadow: 0 20px 30px 0 rgba(0 0 0 / 10%);
    }
    .button {
      color: $white;
    }
  }
  .contentcontainer:not(.contentcontainer--white) + & {
    padding-top: 0;
  }
}

@include breakpoint(medium) {
  .register-cta {
    padding-bottom: 14rem;
    .knowly {
      left: $register-cta-knowly-offset-medium;
      width: 380px;
    }
    .mountains {
      left: $register-cta-mountains-offset-medium;
    }
  }
}

@include breakpoint(large) {
  .register-cta {
    .knowly {
      left: $register-cta-knowly-offset-large;
    }
  }
}
