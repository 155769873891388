.off-canvas {
  box-shadow: inset 0 2px 6px rgba($header-color, .14);
  box-sizing: border-box;
  color: $off-canvas-font-color;
  max-height: 100vh;
  padding: 2rem 0 0;
  &-wrap {
    &.move-left,
    &.move-right {
      max-height: 100%;
      /* stylelint-disable-next-line declaration-no-important */
      overflow: hidden !important;
      .off-canvas {
        max-height: 100vh;
        min-height: 100vh;
      }
    }
  }
  &__title,
  a {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &__user {
    margin-top: 2rem;
    padding: $global-menu-padding;
  }
  &__username {
    color: $off-canvas-font-color;
    display: block;
    margin: .75rem 0 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .off-canvas__title {
    display: block;
    font-size: rem-calc(13);
    font-weight: 300;
    margin: 0;
    padding: 1rem 1rem .5rem;
  }
  &__title,
  .menu:not(.dropdown-pane) li a {
    color: $off-canvas-font-color;
  }
  > .menu {
    a:hover,
    .active > a {
      color: $secondary-color;
    }
    a:not(.button) {
      font-size: rem-calc(14);
      position: relative;
    }
    &.level2 {
      padding-bottom: .5rem;
      > li {
        line-height: 1.4;
        margin-bottom: 1px;
        > a:not(.button) {
          border-radius: $global-radius 0 0 $global-radius;
        }
      }
      > .active > a {
        font-weight: normal;
      }
    }
  }

  &-list {
    margin: 0 0 1rem;
    padding: 0 0 1rem;
    &:first-child {
      padding-top: 1rem;
    }
    &.level2 {
      margin: 0;
      &:last-child {
        border-bottom: 0;
      }
      .has-button {
        padding: .5rem 1rem 0;
      }
      > li {
        margin-bottom: 1px;
      }
      > .hier > a,
      > .active > a,
      > li > a:hover,
      li > a:focus {
        background-color: rgba($header-color, .1);
      }
    }
    + .off-canvas-list {
      margin-top: 1.5rem;
    }
  }
  &__dropdown {
    padding: $global-menu-padding;
    .dropdown-pane {
      /* stylelint-disable-next-line declaration-no-important */
      left: 1rem !important;
    }
    &-title {
      color: $off-canvas-title-font-color;
    }
    > a {
      color: $off-canvas-font-color;
      display: inline-block;
      height: auto;
      padding: $global-menu-padding;
    }
  }
}

.left,
.right {
  &-off-canvas-toggle {
    &,
    &:hover {
      color: $white;
    }
  }
}

.inner-wrap {
  min-height: 100vh;
}

@include breakpoint(medium) {
  .off-canvas {
    padding: 0 .5rem;
    &::-webkit-scrollbar {
      background-color: rgba($body-font-color, .2);
      width: 8px;
      &-button {
        display: none;
      }
      &-thumb {
        background-color: rgba($body-font-color, .4);
        height: 50px;
      }
    }
    &.reveal-for-medium {
      background: none transparent;
      position: relative;
      width: 100%;
    }
  }
  .has-left-off-canvas {
    .off-canvas-content {
      overflow: visible;
    }
  }

  .left-off-canvas-menu--dashnav {
    .off-canvas__title {
      color: $body-font-color;
    }
    .off-canvas-list {
      &.menu li a,
      a {
        color: $body-font-color;
      }
      .open > a,
      .hier > a,
      .active > a,
      a:hover:not(.button) {
        color: $body-font-color;
      }
      &.menu a > span {
        @include beta-label;
      }
    }
  }
}
