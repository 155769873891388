@use 'sass:color';
/* stylelint-disable selector-max-type */
/* stylelint-disable declaration-no-important */
table {
  border-collapse: collapse;
  font-size: inherit;
  text-align: left;
  width: 100%;
  thead,
  tbody tr {
    border-bottom: 1px solid $gainsboro;
  }
  tr {
    &.row--highlighted {
      background-color: color.scale($info-color, $lightness: 87.5%);
    }
  }
  td {
    &.column--textinput {
      padding: 0;
      input {
        background-color: transparent;
        border-color: $gainsboro;
        border-width: 0 1px;
        margin: 0;
        padding: $form-spacing * .5;
      }
    }
    &.column--checkbox {
      width: 1%;
    }
  }
  .formcontrol {
    margin-bottom: 0;
    max-width: none;
  }

  // truncate
  tbody td {
    position: relative;
  }
  .truncate,
  a[href*='mailto:'] {
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    position: absolute;
    text-overflow: ellipsis;
    top: 50%;
    transform: translateY(-50%);
    white-space: nowrap;
  }
}

.column {
  &--grow {
    width: 100%;
  }
  &--break {
    word-break: break-all;
  }
}

.table {
  display: table;
  &__row {
    display: table-row;
  }
  &__heading {
    font-weight: bold;
  }
  &__data {
    display: table-cell;
  }

  // table for displaying data compact
  &--data {
    .formcontrol {
      margin: 0;
      &__inner {
        min-height: 0 !important;
      }
      &__label {
        color: inherit;
        font-size: 1rem;
      }
    }
  }
  &--striped {
    tbody tr:nth-child(odd) {
      background-color: $snow;
    }
  }
  &--statistics {
    margin-bottom: 0;
    tbody tr:last-child {
      border-bottom: 0;
    }
  }

  // mini table in e.g. a panel
  &--compact {
    th,
    td {
      font-size: rem-calc(13);
      padding: .25rem .5rem;
      vertical-align: top;
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }
    }
    tbody tr {
      border: 0;
    }
    &-break {
      td {
        word-wrap: break-word;
      }
    }
  }
}

.api__table,
.table--horizontal {
  th,
  td {
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }
}

@include breakpoint(medium) {
  .table {
    // Statistics table
    &--statistics {
      .column--label {
        width: 5%;
      }
    }
    &--data {
      td,
      th {
        padding: .75rem .5rem;
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
        }
      }
    }
    &--condensed {
      td,
      th {
        padding-bottom: .5rem;
        padding-top: .5rem;
      }
    }
  }
}

@include breakpoint(small only) {
  .table {
    // Statistics table
    &--statistics {
      tr {
        @include clearfix;
        display: block !important;
      }
      thead {
        display: none !important;
      }
      .column--label {
        float: left;
        position: relative;
        width: 28px;
      }
      .column--answer {
        float: left;
        margin-top: .25rem;
        padding-left: 5px;
        position: relative;
        width: 90%;
        width: calc(100% - 28px);
        &::before {
          display: none;
        }
      }
      .column--category {
        clear: both;
      }
      .column--progressbar {
        clear: left;
        float: left;
        position: relative;
        width: 66.7%;
        &::before {
          display: none;
        }
      }
      .column--number {
        float: left;
        position: relative;
        text-align: right !important;
        width: 33.3%;
      }
      .row--total {
        padding-top: .75rem;
        text-align: right !important;
        td:empty {
          display: none;
        }
        td {
          display: inline-block;
          width: auto;
        }
      }
    }
  }
  .table--vertical-on-small {
    &,
    tbody,
    tr,
    th,
    td {
      display: block;
    }
    tr > * + * {
      padding-top: 0;
    }
    tbody {
      th,
      td {
        padding-left: 0;
        padding-right: 0;
      }
    }
    &.api__table {
      td,
      th {
        padding-left: 0;
        padding-right: 0;
      }
    }
    a {
      word-break: break-all;
    }
  }
}

@media print {
  td,
  th {
    font-size: 13px !important;
  }
}

/* stylelint-enable */
