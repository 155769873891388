$header-fixed-box-shadow-color-1: rgba($header-color, .4);
$header-fixed-box-shadow-color-2: rgba($black, .24);

/* stylelint-disable declaration-no-important */
.header {
  background-color: $white;
  border-radius: 0;
  min-height: 55px;
  padding: 1rem 0;
  transition: box-shadow 200ms ease;
  &__inner {
    margin: 0 auto;
    max-width: 880px;
    width: 100%;
  }
  &__title,
  &__title[class*='heading--h'] {
    display: inline-block;
    margin-bottom: 0;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    .mdi {
      display: inline-block;
      margin-left: -26px;
      position: absolute;
      &::before {
        vertical-align: middle;
      }
    }
  }
  a:hover .header__title {
    color: $secondary-color;
  }
  &__title-path {
    color: $jumbo;
  }
  &.fixed {
    box-shadow: 0 1px 40px $header-fixed-box-shadow-color-1, 0 1px 2px $header-fixed-box-shadow-color-2;
    z-index: 1003;
  }
}

@include breakpoint(medium) {
  .header {
    .column {
      align-items: center;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      line-height: 0;
      &.text-left {
        justify-content: flex-start;
      }
    }
    &__left {
      left: 1rem;
    }
    &__left,
    &__right {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
    &__right {
      right: 1rem;
    }
    .button-group {
      white-space: nowrap;
      + .button {
        margin-left: 1rem;
      }
    }
    &--dashboard {
      .header__inner {
        max-width: $global-width;
        padding: 0 1rem;
      }
    }
  }
}

@include breakpoint(medium only) {
  .header {
    width: 100%;
    &__inner {
      margin-left: 2rem;
    }
    &__action {
      &--back {
        top: 1.5rem;
      }
    }
    &__exam-link {
      display: none;
    }
    &__title,
    &__title[class*='heading--h'] {
      margin-bottom: .25rem;
    }
  }
}

@include breakpoint(small only) {
  .header {
    &__title,
    &__title[class*='heading--h'] {
      max-width: 80%;
    }
    &__action {
      position: relative;
      transform: none;
      &--back {
        margin-right: 5px;
      }
    }
    &__inner {
      a {
        margin-left: 26px;
      }
    }
    &__exam-link {
      display: none;
    }
    &__right-actions {
      float: none;
      margin-top: -2rem;
      .button {
        margin-top: .5rem;
      }
    }
    .button {
      clear: both;
      float: none !important;
    }
    &.fixed {
      // no fixtures on them tiny mobiles please
      position: static !important;
    }
    &__manage-categories {
      position: absolute;
      right: 1rem;
      top: 0;
    }
  }
}
/* stylelint-enable */
