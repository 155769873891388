.tooltip {
  z-index: 999999;
  &-indicator {
    display: block;
    padding-left: 30px;
    > .icon {
      float: left;
      line-height: 1;
      margin-left: -30px;
    }
  }
  > .nub,
  > .nub.rtl {
    /* stylelint-disable declaration-no-important */
    left: $tooltip-pip-width !important;
    right: auto !important;
    /* stylelint-enable */
  }
  a {
    color: $white;
    text-decoration: underline;
  }
  &--wide {
    max-width: $tooltip-max-width * 1.5;
  }
}
