// Pricing table
$smart-choice-color: $info-color;

.pricing-table {
  border-collapse: separate;
  border-spacing: 0;
  margin: 0 0 2rem;
  width: 100%;
  tr {
    background: $white;
  }
  th,
  td {
    padding: 1rem 1.5rem;
    width: 33.333%;
    &:nth-child(2) {
      border-left: 2px solid $smart-choice-color;
      border-right: 2px solid $smart-choice-color;
    }
  }

  // Headers
  th {
    color: $header-color;
    font-weight: $header-font-weight;
    text-align: center;
  }
  tr.pricing-table__smart-choice-row {
    background: transparent;
    td {
      border-radius: $global-radius $global-radius 0 0;
      padding-bottom: .5rem;
      padding-top: .5rem;
      position: relative;
      text-align: center;
    }
    .pricing-table__smart-choice-owl {
      position: absolute;
      right: -23px;
      top: -69px;
      width: 150px;

      @include breakpoint(medium) {
        right: -50px;
      }

      @include breakpoint(large) {
        right: -31px;
      }
    }
  }
  &__smart-choice {
    background: $smart-choice-color;
    color: $white;
    font-size: $small-font-size;
    font-weight: $global-weight-bold;
    text-transform: uppercase;
  }
  &__subscription-title-row {
    th {
      border-radius: $global-radius $global-radius 0 0;
      font-size: rem-calc(map-deep-get($header-styles, 'small', 'h3', 'font-size'));
      padding-bottom: 0;
      &:nth-child(2) {
        border-radius: 0;
      }

      @include breakpoint(medium) {
        font-size: rem-calc(map-deep-get($header-styles, 'medium', 'h3', 'font-size'));
      }
    }
  }
  &__price {
    color: $black;
    font-size: 2.75rem;
    font-weight: bold;
    transition: color .25s ease;
    &--small {
      font-size: 2rem;
    }
  }
  .button {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  // Features
  &__feature-row {
    td {
      border-bottom: 1px solid $medium-gray;
      padding-left: calc(1.5rem + 24px);
      position: relative;
      vertical-align: top;
      .icon {
        left: 1rem;
        position: absolute;
        top: .5rem;
      }
    }
    &--first td {
      border-top: 1px solid $medium-gray;
    }
    &:last-child td {
      border-bottom: 0;
    }
  }
  &__feature-content {
    display: block;
  }

  // CTA buttons
  &__cta-row {
    th,
    td {
      padding-top: .5rem;
    }
    &:last-child td {
      padding-bottom: .5rem;
      padding-top: 2rem;
      &:nth-child(2) {
        border-bottom: 2px solid $smart-choice-color;
      }
    }
  }

  // Academy portal addon
  &__addon {
    & &-wrapper {
      box-shadow: $card-4-shadow;
      padding: 0;
      width: 100%;
    }
    &-content {
      padding: 2rem 3rem 3rem;
    }
    &-features {
      display: inline-block;
      li {
        margin: 0 0 .5rem;
        padding: 0 0 0 1.5rem + rem-calc(24);
        position: relative;
      }
      .mdi {
        left: 0;
        line-height: 1;
        position: absolute;
      }
    }
    &-fee td {
      border-top: 1px solid $medium-gray;
    }
    &-cta-row td {
      padding-top: 0;
    }
    .pricing-table {
      margin: 0;
    }
  }

  @include breakpoint(large) {
    &[data-pricing] {
      border-spacing: 2rem 0;
    }
  }

  @include breakpoint(medium) {
    &[data-pricing] {
      transform: none !important; /* stylelint-disable-line declaration-no-important */
    }
  }

  @include breakpoint(small only) {
    &[data-pricing] {
      position: relative;
      width: 300%;
    }
    &[style*='-33'] &__addon-content {
      transform: translateX(100%);
    }
    &[style*='-66'] &__addon-content {
      transform: translateX(200%);
    }
    &-container {
      overflow-x: scroll;
      padding-left: 1rem;
      padding-right: 1rem;
      &--swipe-init {
        overflow-x: hidden;
        [data-pricing] {
          transition: transform .3s ease-in-out;
        }
        .pricing-table__addon-content {
          transition: transform .35s ease-in-out;
          width: 33.3333%;
        }
        .pricing-table__addon-features {
          column-count: 1;
        }
      }
    }
  }
}

.pricing {
  &-jumbotron {
    margin-bottom: -17.4rem;
    padding-bottom: 16rem;
  }
  &__yearly-switch {
    float: none;
    margin-top: 2rem;
    &-container {
      display: inline-block;
    }
  }
  &__yearly-discount {
    align-items: center;
    border-radius: 50%;
    display: flex;
    font-weight: $global-weight-bold;
    height: 32px;
    justify-content: center;
    position: absolute;
    right: -19px;
    text-align: center;
    top: -32px;
    transform: rotate(-15deg);
    width: 32px;
  }
  &__currency-switch {
    @include breakpoint(medium down) {
      margin-right: -2rem;
    }

    @include breakpoint(small only) {
      margin: 1.76rem 0 0;
    }

    @include breakpoint(medium) {
      margin-bottom: -9rem;
    }
  }
  &__switches {
    @include breakpoint(500px down) {
      .column {
        text-align: center;
        width: 100%;
      }
    }
  }
  &__payment-list {
    margin: -.5rem 0;
    opacity: .8;
    > li {
      display: inline-block;
      float: none;
      margin: 0 .25rem;
    }

    @include breakpoint(medium) {
      .pf::before {
        font-size: 32px;
      }
    }
  }
  &__faq ul {
    list-style: none;
    margin-left: 0;

    @include breakpoint(medium) {
      column-count: 2;
    }
    > li {
      display: inline-block;
      width: 100%;
    }
    h3 {
      font-family: $font-family-serif;
    }
  }
}

.page--pricing {
  .top-bar-right .menu .button.round {
    display: none; /* Hide login and register buttons */
  }
}
