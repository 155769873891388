.intro {
  color: $header-color;
}

.cmscontent,
.intro {
  .button {
    border-radius: 99999px;
  }
}

.text-center > p {
  max-width: none;
}

// Headings and fake heading styles for when u want to use strongs for example or h1's in lists
[class*='heading--h'],
fieldset legend {
  color: $header-color;
  display: block;
  font-family: $header-font-family;
  font-style: $header-font-style;
  font-weight: $header-font-weight;
  line-height: $header-lineheight;
  margin-bottom: $header-margin-bottom;
}

.heading--h1 {
  font-size: rem-calc(map-deep-get($header-styles, 'small', 'h1', 'font-size'));
}

.heading--h2,
legend {
  font-size: rem-calc(map-deep-get($header-styles, 'small', 'h2', 'font-size'));
}

fieldset legend,
.heading--h3 {
  font-size: rem-calc(map-deep-get($header-styles, 'small', 'h3', 'font-size'));
}

.heading--h4 {
  font-size: rem-calc(map-deep-get($header-styles, 'small', 'h4', 'font-size'));
}

.heading--h5 {
  font-size: rem-calc(map-deep-get($header-styles, 'small', 'h5', 'font-size'));
}

.heading--h6 {
  font-size: rem-calc(map-deep-get($header-styles, 'small', 'h6', 'font-size'));
}

.heading {
  &--serif {
    font-family: $font-family-serif;
    font-weight: $global-weight-bold;
  }
  &--jumbo {
    font-size: 2rem;
    line-height: 1.2;
  }
  &--lead {
    * & {
      color: $header-color;
    }
  }
  &--secondary {
    color: $secondary-color;
  }
}

@include breakpoint(medium) {
  .heading {
    &--h1 {
      font-size: rem-calc(map-deep-get($header-styles, 'medium', 'h1', 'font-size'));
    }
    &--h2 {
      font-size: rem-calc(map-deep-get($header-styles, 'medium', 'h2', 'font-size'));
    }
    &--h3 {
      font-size: rem-calc(map-deep-get($header-styles, 'medium', 'h3', 'font-size'));
    }
    &--h4 {
      font-size: rem-calc(map-deep-get($header-styles, 'medium', 'h4', 'font-size'));
    }
    &--h5 {
      font-size: rem-calc(map-deep-get($header-styles, 'medium', 'h5', 'font-size'));
    }
    &--h6 {
      font-size: rem-calc(map-deep-get($header-styles, 'medium', 'h6', 'font-size'));
    }
    &--jumbo {
      font-size: 2.6rem;
    }
  }
  fieldset legend,
  .heading--h3 {
    font-size: rem-calc(map-deep-get($header-styles, 'medium', 'h3', 'font-size'));
  }
}

// img in text
.links {
  float: left;
  margin: 0 $global-margin $global-margin 0;
}

.rechts {
  float: right;
  margin: 0 0 $global-margin $global-margin;
}

// text align
.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

/*! rtl:begin:ignore */
.text-right--force {
  text-align: right;
}

.text-left--force {
  text-align: left;
}

/*! rtl:end:ignore */

// Text in colors
.text-success {
  color: $success-color;
}

.text-warning {
  color: $warning-color;
}

.text-alert {
  color: $alert-color;
}

.text-info {
  color: $info-color;
}

.text-primary {
  color: $primary-color;
}

.text-secondary {
  color: $secondary-color;
}

.text-black {
  color: $black;
}

.text-white {
  color: $white;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-serif {
  font-family: $font-family-serif;
}

.readmore {
  .mdi::before {
    line-height: 1;
    vertical-align: middle;
  }
  a:hover {
    text-decoration: underline;
  }
}

.paragraph--nomargin {
  margin-bottom: 0;
}

code {
  word-break: break-all;
}

dd {
  margin-bottom: $global-margin * .5;
}

.section-intro {
  font-size: 1.1rem;
  margin: 1rem 0;
}
