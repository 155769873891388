/*! rtl:begin:ignore */

@keyframes animation-mountain-flying-knowly {
  0% {
    transform: translate3d(100px, 0, 0) rotate(15deg);
  }
  50% {
    transform: translate3d(100px, 10px, 0) rotate(15deg);
  }
  100% {
    transform: translate3d(100px, 0, 0) rotate(15deg);
  }
}

/*! rtl:end:ignore */
