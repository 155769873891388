.child--info-list ul,
.info-list {
  margin: 0;
  padding: 0;
  li {
    font-size: .9rem;
    list-style: none;
    margin-top: .5rem;
    &:first-child {
      margin-top: 0;
    }
  }
  strong {
    display: block;
    font-size: 1rem;
    line-height: 1;
  }
}

.linklist li {
  margin-top: .25rem;
  padding-left: $subnav-item-padding-left;
  position: relative;
  &:first-child {
    margin-top: 0;
  }
  > a {
    &::before {
      @include materialiconize('chevron-right', 18);
      left: $subnav-chevron-offset;
      position: absolute;
      top: .15rem;
    }
  }
}

.spaced-list {
  margin-bottom: 1.5rem;
  li + li {
    margin-top: 1rem;
  }
}

.inline-list {
  list-style: none;
  margin: 0 0 1.1333rem -1.4667rem;
  padding: 0;
  > li {
    display: block;
    float: left;
    list-style: none;
    margin-left: 1.4667rem;
  }
  &--small {
    margin: 0 0 1rem -.75rem;
    > li {
      margin-left: .75rem;
    }
  }
}

.steplist {
  counter-reset: steplist-counter;
  list-style: none;
  margin: 1rem 0 0;
  padding: 0;
  &__title {
    color: $secondary-color;
    margin-bottom: 1.55rem;
  }
  &__item-title {
    font-size: rem-calc(map-deep-get($header-styles, 'small', 'h4', 'font-size'));
    margin: 0 0 .5rem;

    @include breakpoint(medium) {
      font-size: rem-calc(map-deep-get($header-styles, 'medium', 'h4', 'font-size'));
    }
  }
  li {
    counter-increment: steplist-counter;
    margin: 0;
    padding: .25rem 0 .25rem 2.75rem;
    position: relative;
    &::before {
      background: $secondary-color;
      border-radius: 50%;
      color: $white;
      content: counter(steplist-counter);
      font-size: 1.2rem;
      font-weight: $global-weight-bold;
      height: 2rem;
      left: 0;
      line-height: 2rem;
      position: absolute;
      text-align: center;
      top: 0;
      width: 2rem;
    }
  }
}
