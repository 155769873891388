/*! rtl:begin:ignore */

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-in-up {
  0% {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

@keyframes fade-in-right {
  0% {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

@keyframes fade-out-left {
  0% {
    opacity: 1;
    transform: none;
  }
  100% {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes swing-left-right {
  2% { transform: translateX(-1%); }
  4% { transform: translateX(-1.5%); }
  6% { transform: translateX(-2.5%); }
  8% { transform: translateX(-.5%); }
  10% { transform: translateX(-.5%); }
  12% { transform: translateX(-1.5%); }
  14% { transform: translateX(-2.5%); }
  16% { transform: translateX(-1.5%); }
  18% { transform: translateX(-2.5%); }
  20% { transform: translateX(-2.5%); }
  22% { transform: translateX(-.5%); }
  24% { transform: translateX(-1.5%); }
  26% { transform: translateX(-1.5%); }
  28% { transform: translateX(-2.5%); }
  30% { transform: translateX(-2.5%); }
  32% { transform: translateX(-2.5%); }
  34% { transform: translateX(-1.5%); }
  36% { transform: translateX(-1.5%); }
  38% { transform: translateX(-1.5%); }
  40% { transform: translateX(-1.5%); }
  42% { transform: translateX(-2.5%); }
  44% { transform: translateX(-1.5%); }
  46% { transform: translateX(-.5%); }
  48% { transform: translateX(-.5%); }
  50% { transform: translateX(-1.5%); }
  52% { transform: translateX(-.5%); }
  54% { transform: translateX(-.5%); }
  56% { transform: translateX(-1.5%); }
  58% { transform: translateX(-2.5%); }
  60% { transform: translateX(-2.5%); }
  62% { transform: translateX(-2.5%); }
  64% { transform: translateX(-.5%); }
  66% { transform: translateX(-1.5%); }
  68% { transform: translateX(-.5%); }
  70% { transform: translateX(-.5%); }
  72% { transform: translateX(-2.5%); }
  74% { transform: translateX(-2.5%); }
  76% { transform: translateX(-2.5%); }
  78% { transform: translateX(-2.5%); }
  80% { transform: translateX(-2.5%); }
  82% { transform: translateX(-.5%); }
  84% { transform: translateX(-1.5%); }
  86% { transform: translateX(-2.5%); }
  88% { transform: translateX(-.5%); }
  90% { transform: translateX(-2.5%); }
  92% { transform: translateX(-.5%); }
  94% { transform: translateX(-2.5%); }
  96% { transform: translateX(-1.5%); }
  98% { transform: translateX(-1.5%); }
}

/*! rtl:end:ignore */
