$preview-browser-color: $silver;

.preview-browser {
  display: inline-block;
  margin: 0 0 2rem;
  position: relative;
  vertical-align: middle;

  &__window,
  &__bar {
    background-color: $preview-browser-color;
    fill: $preview-browser-color;
  }

  &__window {
    border-radius: 8px 8px 0 0;
    overflow: hidden;
  }

  &__content {
    border: 1px solid $preview-browser-color;
  }
  &__knowly-container {
    position: absolute;
    right: 1rem;
    top: -1rem;
    width: 25%;
    z-index: 1;

    .breakout-container-alternate .breakout:nth-child(odd) & {
      bottom: -2rem;
      top: auto;
      width: 20%;
    }

    @include breakpoint(large) {
      bottom: -5rem;
    }

    @include breakpoint(medium) {
      bottom: -3rem;
    }
  }

  &__knowly {
    background: url('../images/deadlyduck/illustrations/knowly--flying-looking-down.svg') no-repeat center bottom/100%;
    padding-top: 70%;
    width: 100%;

    .breakout-container-alternate .breakout:nth-child(odd) & {
      background: url('../images/deadlyduck/illustrations/knowly--on-branch-chair-pointing-left.svg') no-repeat center bottom/100%;
      padding-top: 130%;
    }
  }

  @include breakpoint(medium) {
    margin: 0 2rem 0 0;
    .breakout-container-alternate .breakout:nth-child(odd) & {
      margin: 0 0 0 2rem;
    }
  }

  @include breakpoint(1500px) {
    margin: 0 2rem 0 -10vw;
    .breakout-container-alternate .breakout:nth-child(odd) & {
      margin-left: 2rem;
      margin-right: -10vw;
    }
  }
}
