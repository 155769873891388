@use 'sass:map';

@mixin button-bar-collapse {
  .buttonbar {
    padding: 0;
    .row {
      display: flex;
    }
    .column {
      padding: 0;
    }
    .button {
      border-radius: 0;
      width: 100%;
    }
  }
}

.reveal-open {
  overflow: hidden;
}

/* stylelint-disable declaration-no-important */
.reveal {
  background: none;
  box-shadow: none;
  left: 0;
  outline: none;
  overflow: visible;
  padding: 0;
  right: 0;
  z-index: 5001;
  &__inner {
    background: $reveal-background;
    max-height: 80vh;
    overflow-x: hidden;
    overflow-y: auto;
    padding: $reveal-padding;
    .row {
      margin-left: 0;
      margin-right: 0;
      .row {
        margin-left: -(map.get($grid-column-gutter, medium) * .5);
        margin-right: -(map.get($grid-column-gutter, medium) * .5);
      }
    }
  }
  &-bg {
    z-index: 5000;
  }
  &.tiny,
  &.small,
  &.medium,
  &.large,
  &.xlarge {
    width: 100%;
  }
  &.tiny,
  &.small {
    @include button-bar-collapse;
    &.reveal--no-buttonbar {
      padding-bottom: 0;
    }
    .buttonbar .row {
      flex-direction: row-reverse;
    }
  }
  &.full {
    border-radius: 0;
    bottom: 0 !important;
    left: 0 !important;
    max-width: none;
    position: fixed;
    right: 0 !important;
    top: 0 !important;
  }
  .close-button,
  &__button--prev {
    display: inline-block;
    font-size: inherit;
    line-height: 1;
    .mdi {
      display: inline-block;
      vertical-align: middle;
      &::before {
        font-size: 24px;
      }
    }
  }
  .buttonbar {
    bottom: 0;
    left: 2rem;
    margin: 0;
    padding: ($reveal-padding * .5) $reveal-padding;
    position: absolute;
    right: 2rem;
    width: auto;
    .row {
      max-width: none;
    }
    .close-button {
      right: auto;
      top: auto;
    }
  }
  > .card {
    border-radius: $global-radius $global-radius 0 0;
    + .reveal__inner {
      border-radius: 0 0 $global-radius $global-radius;
    }
  }
  &__title {
    margin-bottom: $global-margin;
    + .row > .row {
      margin-bottom: 1rem !important;
      margin-top: 1rem !important;
    }
  }
}

@include breakpoint(medium) {
  .reveal {
    padding: 0 2rem 67px;
    &::after {
      background: $reveal-background;
      border-radius: $global-radius;
      bottom: 0;
      box-shadow: $card-5-shadow;
      content: '';
      display: block;
      left: 2rem;
      position: absolute;
      right: 2rem;
      top: 0;
      z-index: -1;
    }
    &--no-buttonbar {
      padding: 0 2rem;
      .reveal__inner {
        border-radius: $global-radius;
      }
    }
    &__inner {
      border-radius: $global-radius $global-radius 0 0;
      padding: 2 * $reveal-padding;
    }
    .close-button {
      right: 3rem;
      top: 1rem;
      z-index: 5500;
    }
    &:not(.tiny, .small) .buttonbar {
      .close-button,
      .reveal__button--prev {
        background: none;
        padding-left: 0;
        padding-right: 0;
        &:hover,
        &:focus {
          background: none;
        }
      }
    }
    &.tiny {
      max-width: .4 * $global-width;
    }
    &.small {
      max-width: .5 * $global-width;
    }
    &.medium {
      max-width: .6 * $global-width;
    }
    &.large {
      max-width: .7 * $global-width;
    }
    &.xlarge {
      max-width: .9 * $global-width;
    }
    &.full {
      height: 100%;
      padding-left: 0;
      padding-right: 0;
      padding-top: 0;
      .reveal__inner {
        border-radius: 0;
        height: 100%;
        max-height: none;
      }
      .buttonbar {
        left: 0;
        padding-left: 2 * $reveal-padding;
        padding-right: 7rem;
        right: 0;
      }
      .close-button:not(.button) {
        right: 1rem;
        top: 1rem;
      }
    }
    &.tiny,
    &.small {
      padding-bottom: 52px;
      .buttonbar {
        border-radius: 0 0 $global-radius $global-radius;
        overflow: hidden;
      }
    }
    &:not(.full) {
      top: 10vh !important;
    }
    &.medium,
    &.large,
    &.xlarge {
      .buttonbar {
        padding-left: 2 * $reveal-padding;
        padding-right: 2 * $reveal-padding;
      }
    }
  }
}

@include breakpoint(small only) {
  .reveal-open {
    padding-right: 0 !important;
  }
  .reveal {
    @include button-bar-collapse;
    border-radius: 0;
    bottom: 0 !important;
    height: 100%;
    max-height: 90vh;
    max-width: none;
    min-height: 100%;
    top: 0 !important;
    &:not(.reveal--no-buttonbar) {
      padding-bottom: 40px;
    }
    &__inner {
      height: 100%;
      max-height: none;
    }
    &__title {
      padding-right: 1.5rem;
    }
    .buttonbar {
      left: 0;
      right: 0;
      .row {
        flex-direction: row-reverse;
      }
      .button {
        font-size: map.get($button-sizes, default);
        padding: 1rem;
      }
    }
    .close-button:not(.button) {
      top: 1.33rem;
    }
  }
}

/* stylelint-enable */
