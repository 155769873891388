/*! rtl:begin:ignore */
.illustration--team {
  &__knowly {
    &--top-right,
    &--top-left {
      animation: animation-flying-knowly linear 1s infinite;
    }
    &--top-left .illustration--team__knowly-eyes {
      animation: animation-team-eyes-looking-left linear 10s infinite;
    }
    &--top-right .illustration--team__knowly-eyes {
      animation: animation-team-eyes-looking-right linear 10s infinite;
      animation-delay: 3.5s;
    }
    &--bottom-left .illustration--team__knowly-eyes {
      animation: animation-team-eyes-looking-right linear 10s infinite;
      animation-delay: 7.5s;
    }
    &--bottom-middle .illustration--team__knowly-eyes {
      animation: animation-team-eyes-looking-right linear 10s infinite;
      animation-delay: 4.5s;
    }
    &--bottom-right .illustration--team__knowly-eyes {
      animation: animation-team-eyes-looking-left linear 10s infinite;
      animation-delay: 6s;
    }
  }
}

/*! rtl:end:ignore */
