.language-menu__button {
  &-lang-code {
    text-transform: uppercase;
  }
  .off-canvas__dropdown & {
    &-lang-code {
      display: none;
    }
  }
  .top-bar & {
    &-label {
      @include element-invisible;
    }
  }

  .off-canvas & {
    color: inherit;
  }
}
