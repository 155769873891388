#snackbar-container {
  align-items: center;
  bottom: 0;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  left: 0;
  padding: 0 8px;
  position: fixed;
  z-index: 999999;
  width: 100%;
}

.snackbar {
  background-color: $snackbar-background-color;
  border-radius: $snackbar-border-radius;
  box-shadow: $snackbar-box-shadow;
  clear: both;
  color: $snackbar-color;
  cursor: pointer;
  font-size: $snackbar-font-size;
  height: 0;
  max-width: $snackbar-max-width;
  min-width: $snackbar-min-width;
  opacity: 0;
  overflow: hidden;
  transform: translateY(200%);
  transition: transform .2s ease-in-out, opacity .2s ease-in, height 0s linear .2s, padding 0s linear .2s, height 0s linear .2s;
  &.snackbar-opened {
    height: auto;
    margin-bottom: 8px;
    opacity: 1;
    padding: 14px 15px;
    transform: none;
    transition: transform .2s ease-in-out, opacity .2s ease-in, height 0s linear .2s, height 0s linear .2s;
    &:last-child {
      margin-bottom: 20px;
    }
  }
  &.toast {
    border-radius: 200px;
  }
  a {
    color: $snackbar-action-color;
    margin: 0 0 0 1rem;
    &:last-child {
      margin-right: 0;
    }
  }
}

@include breakpoint(small only) {
  #snackbar-container {
    left: 0 !important;
    right: 0;
    width: 100%;
    .snackbar {
      min-width: 100%;
    }
    [class='snackbar snackbar-opened'] ~ .snackbar.toast {
      margin-top: 20px;
    }
  }
}
