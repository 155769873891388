// product pagina
.page--product {
  .bgwrap {
    min-height: 0;
    position: relative;
    z-index: 3;
  }
  .owly {
    left: 430px;
  }
  h1 {
    margin: 1em 0;
  }
  h2 {
    text-align: left;
  }
  div[class*='col'] {
    min-height: 1px;
  }
  .createquiz {
    margin: 78px 0 100px;
    h2 {
      margin: 0 0 10px;
    }
  }
  .introbar .col5:first-child {
    h2 {
      margin-top: 60px;
    }
  }
  .page-wrap {
    /* stylelint-disable-next-line declaration-no-important */
    margin-top: 0 !important;
  }
}
