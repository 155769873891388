// alignment helpers
$sizes: small, medium, large;

@each $size in $sizes {
  @include breakpoint(#{$size}) {
    $size: #{$size}-;

    @if $size == small- {
      $size: '';
    }

    @for $i from 0 through 8 {
      .#{$size}pull-top-#{$i} {
        margin-top: -#{$i}rem;
      }
      .#{$size}pull-bottom-#{$i} {
        margin-bottom: -#{$i}rem;
      }
      .#{$size}offset-top-#{$i} {
        margin-top: #{$i}rem;
      }
      .#{$size}offset-bottom-#{$i} {
        margin-bottom: #{$i}rem;
      }
      .#{$size}offset-inside-#{$i} {
        padding-bottom: #{$i}rem;
        padding-top: #{$i}rem;
      }
    }
  }
}

@include breakpoint(medium) {
  .flex-content-center {
    align-items: center;
    display: flex;
    justify-content: center;
  }
}

.push-right-1 {
  margin-right: 1rem;
}

@include breakpoint(medium only) {
  .medium-clear-left {
    clear: left;
  }
}

.show-for-sr {
  clip: rect(1px, 1px, 1px, 1px) !important; /* stylelint-disable-line declaration-no-important */
  clip-path: inset(50%);
  font-size: 0;
  left: auto;
  top: auto;
}

.right {
  align-self: flex-end;
}
