/* stylelint-disable declaration-no-important */
.right {
  float: right;
}

.left {
  float: left;
}

@include breakpoint(small only) {
  .small-left { float: left !important; }
  .small-right { float: right !important; }
  .small-only-left { float: left !important; }
  .small-only-right { float: right !important; }
}

@include breakpoint(medium) {
  .medium-left { float: left !important; }
  .medium-right { float: right !important; }
}

@include breakpoint(medium only) {
  .medium-only-left { float: left !important; }
  .medium-only-right { float: right !important; }
}

@include breakpoint(large) {
  .large-left { float: left !important; }
  .large-right { float: right !important; }
}

@include breakpoint(large only) {
  .large-only-left { float: left !important; }
  .large-only-right { float: right !important; }
}

@include breakpoint(xlarge) {
  .xlarge-left { float: left !important; }
  .xlarge-right { float: right !important; }
}

@include breakpoint(xlarge only) {
  .xlarge-only-left { float: left !important; }
  .xlarge-only-right { float: right !important; }
}

/* stylelint-enable */
