.avatar {
  border-radius: $global-radius;
  margin: 0;
  &__outer-container {
    display: inline-block;
    .off-canvas__user &,
    .top-bar & {
      float: left;
      margin-right: .5rem;
    }
  }
}
