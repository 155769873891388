/*! rtl:begin:ignore */
.illustration--feature-overview {
  &__feature1,
  &__feature2,
  &__feature3,
  &__feature4,
  &__feature5,
  &__feature6 {
    animation-duration: .5s;
    animation-fill-mode: forwards;
    animation-name: animation-fade-in;
    animation-timing-function: linear;
    opacity: 0;
  }
  &__feature-check-circle {
    animation-duration: .5s, .75s;
    animation-fill-mode: forwards, forwards;
    animation-name: animation-fade-in, animation-fade-out;
    animation-timing-function: linear, linear;
    opacity: 0;

    > path {
      animation-duration: .4s;
      animation-fill-mode: forwards;
      animation-name: animation-pop;
      animation-timing-function: ease-in-out;
      transform-box: fill-box;
      transform-origin: center;
    }
  }
  &__feature5 {
    animation-delay: 1s;
    .illustration--feature-overview__feature-check-circle {
      animation-delay: 3s, 7s;
      > path {
        animation-delay: 3.2s;
      }
    }
  }
  &__feature2 {
    animation-delay: 1.1s;
    .illustration--feature-overview__feature-check-circle {
      animation-delay: 3.5s, 7s;
      > path {
        animation-delay: 3.7s;
      }
    }
  }
  &__feature1 {
    animation-delay: 1.2s;
    .illustration--feature-overview__feature-check-circle {
      animation-delay: 4s, 7s;
      > path {
        animation-delay: 4.2s;
      }
    }
  }
  &__feature3 {
    animation-delay: 1.3s;
    .illustration--feature-overview__feature-check-circle {
      animation-delay: 4.5s, 7s;
      > path {
        animation-delay: 4.7s;
      }
    }
  }
  &__feature4 {
    animation-delay: 1.4s;
    .illustration--feature-overview__feature-check-circle {
      animation-delay: 5s, 7s;
      > path {
        animation-delay: 5.2s;
      }
    }
  }
  &__feature6 {
    animation-delay: 1.5s;
    .illustration--feature-overview__feature-check-circle {
      animation-delay: 5.5s, 7s;
      > path {
        animation-delay: 5.7s;
      }
    }
  }
  &__knowly-eyes {
    > path {
      animation-delay: 9s;
      animation-duration: 8s;
      animation-iteration-count: infinite;
      animation-name: animation-generic-vacancy-eyes-looking-right;
    }
  }
  &__knowly-closed-eyes {
    animation-delay: 7.5s, 8.5s;
    animation-duration: .05s, .05s;
    animation-fill-mode: forwards;
    animation-name: animation-fade-in, animation-fade-out;
    animation-timing-function: linear, linear;
    opacity: 0;
  }
  &__knowly-nose {
    animation-delay: 8s;
    animation-duration: .5s;
    animation-fill-mode: forwards;
    animation-name: animation-shaking-nose-feature;
    transform-box: fill-box;
    transform-origin: center;
  }
}

/*! rtl:end:ignore */
