@keyframes animation-bouncy-chair {
  0% {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    transform: translate3d(0, 3px, 0);
  }
  25% {
    animation-timing-function: cubic-bezier(.755, .05, .855, .06);
    transform: translate3d(0, 0, 0);
  }
  50% {
    animation-timing-function: cubic-bezier(.755, .05, .855, .06);
    transform: translate3d(0, 3px, 0);
  }
  75% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(0, 3px, 0);
  }
}
