.knowly {
  transform: translate3d(0, 0, 0) #{'/*!rtl:append:scaleX(-1)*/'};
  &--flip-horizontal {
    transform: translate3d(0, 0, 0) scaleX(-1) #{'/*!rtl:append:scaleX(0) */'};
  }
}

.panel {
  .knowly.right { margin: 0 -1rem -1rem 0; }
  .knowly.left { margin: 0 0 -1rem -1; }
}

@include breakpoint(medium) {
  .panel {
    .knowly.right { margin: 0 -2rem -2rem 0; }
    .knowly.left { margin: 0 0 -2rem -2rem; }
  }
}
