.postcard {
  margin-top: 2rem;
  &-container {
    position: relative;
  }

  @include breakpoint(medium) {
    margin: -24% 1rem 0 0;
    position: absolute;
    right: 0;
    transform: rotate(2deg);
    width: 40%;
    z-index: 2;
  }
}
