.author {
  &__image {
    align-items: center;
    display: flex;
    img {
      border-radius: 50%;
    }
  }
  &__description {
    color: $header-color;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }
  &__name {
    color: $header-color;
    display: block;
    font-size: 1rem;
  }
  &__job-title {
    display: block;
  }
  &__company {
    color: $jumbo;
    display: block;
    font-size: 90%;
  }
}
