.icon {
  color: $body-font-color;
  display: inline-block;
  vertical-align: middle;
  &--primary {
    color: $primary-color;
  }
  &--secondary {
    color: $secondary-color;
  }
  &--info {
    color: $info-color;
  }
  &--illustrate {
    color: $base;
  }
  &--success {
    color: $success-color;
  }
  &--warning {
    color: $warning-color;
  }
  &--alert {
    color: $alert-color;
  }
  &--single-line {
    line-height: 1;
  }
  a &,
  button & {
    color: $body-font-color;
  }
  a:hover &,
  a:focus &,
  button:hover &,
  button:focus & {
    color: inherit;
  }
}

// Mirror directional icons for RTL
[class*='mdi-'][class*='-right']::before,
[class*='mdi-'][class*='-left']::before {
  transform: none #{'/*!rtl:scaleX(-1) */'};
}

// Payment font
.pf::before {
  font-size: 24px;
}

.questiontype-icon {
  width: 24px;
}

.mce-panel i.mce-i-code-braces::before {
  @include materialiconize('code-braces', 18px);
  margin-top: -2px;
}

.icon-prefix {
  padding-left: 2rem;
  position: relative;
  &__icon {
    left: 0;
    line-height: 1;
    position: absolute;
    top: -2px;
  }
  &--36 {
    padding-left: 3rem;
  }
}

.icon-background {
  align-items: center;
  background: url('../images/deadlyduck/icon-background.svg') no-repeat center center;
  background-size: cover;
  display: flex;
  height: 80px;
  justify-content: center;
  width: 80px;
}
