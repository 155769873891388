@use 'sass:color';

a {
  &.secondary {
    color: $secondary-color;
    &:hover {
      color: color.scale($secondary-color, $lightness: -14%);
    }
  }
}

.anchor--discrete {
  color: $jumbo;
  &:hover,
  &:focus {
    color: $dark-gray;
  }
}
