.subscription-list {
  list-style: none;
  margin: 1rem 0 3rem;
  position: relative;
  z-index: 2;
  &__item {
    line-height: 1.2;
    opacity: .85;
    vertical-align: bottom;
    &--active {
      font-weight: bold;
      opacity: 1;
      .subscription-list__button {
        display: block;
        line-height: 1.2;
        text-align: left;
        width: 100%;
        .card__inner {
          padding-top: 3rem;
        }
        .card {
          border: 1px solid $iron;
        }
        &:hover .card,
        &:focus .card {
          border-color: $primary-color;
        }
      }
    }

    @include breakpoint(large down) {
      margin-bottom: -1px;
    }
  }
  &__price {
    display: block;
    margin-top: .5rem;
  }
  &__highlighted {
    position: relative;
    z-index: 2;
    &,
    .icon {
      color: $anchor-color;
    }
  }
  &__pill {
    background-color: $gainsboro;
    border-radius: 999px;
    color: $oil;
    display: inline-block;
    font-weight: bold;
    left: 50%;
    line-height: 1;
    padding: 0 .75rem .1rem;
    position: absolute;
    transform: translate(-50%, -24px);
    white-space: nowrap;
  }
  &__action {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    left: -1px;
    position: absolute;
    right: -1px;
    top: -1px;
    &.button {
      padding-right: 0;
    }
  }

  @include breakpoint(medium down) {
    margin-bottom: 4rem;
  }

  @include breakpoint(large) {
    margin-left: -.5rem;
    margin-right: -.5rem;
  }

  @include breakpoint(xlarge) {
    &__item {
      display: inline-block;
      padding: 0 .5rem;
    }
  }

  @include breakpoint(large only) {
    margin-bottom: 0;
    margin-top: 0;
  }

  @include breakpoint(1240px) {
    margin-bottom: 1rem;
  }
}
