.price {
  color: $oil;
  font-family: $font-family-serif;
  font-size: 1.1rem;
  font-weight: $global-weight-bold;
  &__amount {
    display: inline-block;
  }
  &__period {
    display: inline-block;
    font-size: $small-font-size;
  }
  &--tag {
    .price__amount {
      background-color: $jumbo;
      border-radius: 3px;
      color: $white;
      padding: 0 .5rem;
    }
    &.price--alert .price__amount {
      background-color: $alert-color;
    }
    &.price--success .price__amount {
      background-color: $success-color;
    }
  }
  &__amount + .price__period {
    margin-left: .25rem;
  }
}
