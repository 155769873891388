// Only when js active
html:not(.no-js) {
  .formcontrol {
    &--materialize {
      padding-top: 1rem;
      .formcontrol__label {
        font-size: $input-font-size;
        position: absolute;
        top: ($form-spacing * .5);
        transition: 200ms ease-out;
      }
      &.formcontrol--hasvalue,
      &.formcontrol--focus,
      &.formcontrol--hasflatpickr,
      &.formcontrol--select {
        .formcontrol__label {
          font-size: $form-label-font-size;
          left: 0;
          top: -(rem-calc(15));
        }
      }

      // show placeholder on focus
      input,
      textarea {
        margin: 0;
        &::placeholder {
          /* stylelint-disable-next-line declaration-no-important */
          color: $white !important;
          font-weight: normal;
        }
        &:focus {
          &::placeholder {
            /* stylelint-disable-next-line declaration-no-important */
            color: $input-placeholder-color !important;
          }
        }
      }
    }

    // textarea is a little different because it has a border on top
    &--textarea {
      padding-top: 1.5rem;
      .formcontrol__label {
        left: ($form-spacing * .5);
        top: ($form-spacing * .5);
      }
      &.formcontrol--hasvalue,
      &.formcontrol--focus {
        .formcontrol__label {
          top: -($form-spacing * 1.5);
        }
      }
    }

    // select and input[type=number] have auto width
    &--autowidth .formcontrol__inner {
      display: inline-block;
    }
  }
}

@include breakpoint(small only) {
  html:not(.no-js) .formcontrol--materialize.formcontrol--autowidth {
    .formcontrol__label {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
