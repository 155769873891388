// stylelint-disable
$slick-font-family: 'Material Design Icons';
$slick-loader-path: '../images/deadlyduck/';

/*!rtl:begin:ignore*/
@import '~slick-carousel/slick/slick',
'~slick-carousel/slick/slick-theme',
'~@mdi/font/scss/functions';
/*!rtl:end:ignore*/

.slick {
  &-slide {
    background: $white;
    height: auto;
  }
  &-slider {
    margin-left: 50px;
    margin-right: 50px;
  }
  &__container {
    max-width: 800px;
    padding: 0 50px;
    position: relative;
  }
  &-slide__inner {
    padding: 1rem;
    .blockquote {
      margin: 0;
      padding-left: 0;
      padding-right: 0;
    }
  }
  &-next,
  &-prev {
    height: 48px;
    width: 48px;
    &::before {
      color: $body-font-color;
    }
    &:focus,
    &:hover {
      &::before {
        color: $primary-color;
      }
    }
  }
  &-next {
    &,
    [dir="rtl"] & {
      right: -50px;
      &::before {
        @include materialiconize('chevron-right', 48px);
        /*!rtl:raw:
        content:  mdi('chevron-left');
        */
      }
    }
  }
  &-prev {
    &,
    [dir="rtl"] & {
      left: -50px;
      &::before {
        @include materialiconize('chevron-left', 48px);
        /*!rtl:raw:
        content: mdi('chevron-right');
        */
      }
    }
  }
  @include breakpoint(medium) {
    &-slide__inner {
      padding: 2rem;
    }
  }
  @include breakpoint(small only) {
    &-slider {
      margin-left: 25px;
      margin-right: 25px;
    }
    &-next,
    &-prev {
      height: 36px;
      width: 36px;
    }
    &-next {
      &,
      [dir="rtl"] & {
        right: -36px;
        &::before {
          @include materialiconize('chevron-right', 36px);
          /*!rtl:raw:
          content: mdi('chevron-left');
          */
        }
      }
    }
    &-prev {
      &,
      [dir="rtl"] & {
        left: -36px;
        &::before {
          @include materialiconize('chevron-left', 36px);
          /*!rtl:raw:
          content: mdi('chevron-right');
          */
        }
      }
    }
  }
}
