.cmscontent {
  font-size: 1.1rem;

  @include clearfix;
  .intro * {
    font-size: 1.1rem;
  }
  * + h2 {
    margin-top: 1rem;
  }
  h2,
  .heading--h2,
  h3,
  .heading--h3,
  h4,
  .heading--h4,
  h5,
  .heading--h5,
  h6,
  .heading--h6 {
    color: $secondary-color;
  }
  a:hover,
  a:focus {
    text-decoration: underline;
  }
  blockquote:not([class]) {
    margin: 2rem 0;
    padding-left: 3rem;
    position: relative;
    p {
      color: $body-font-color;
      font-family: $font-family-serif;
      font-size: 1.1rem;
      font-weight: $global-weight-bold;
      &:last-of-type {
        margin-bottom: 1rem;
      }
    }
    &::before {
      color: $base;
      content: '\201C';
      font-family: Georgia, 'Times New Roman', serif;
      font-size: 6rem;
      left: 0;
      line-height: 1;
      position: absolute;
      top: 0;
    }
  }
  ul:not([class]) {
    list-style: none;
    li {
      position: relative;
      &::before {
        color: $primary-color;
        content: '•';
        left: -$list-side-margin;
        position: absolute;
        transform: scale(1.25);
      }
    }
  }

  .streamer {
    border-left: 5px solid $primary-color;
    color: $body-font-color;
    font-size: 2.4em;
    font-style: italic;
    font-weight: $global-weight-normal;
    line-height: 1.4;
    margin: 2rem;
    padding: 0 0 0 2rem;
    &::before {
      display: none;
    }
    &.rechts,
    &.links {
      display: inline-block;
      font-size: 1.5em;
      max-width: 50%;
    }
  }

  @include breakpoint(medium) {
    font-size: 1.2rem;
    .intro {
      margin-bottom: 3rem;
      * {
        font-size: 1.2rem;
      }
    }
    *:not(blockquote) + h2 {
      margin-top: 4rem;
    }

    blockquote:not([class]) {
      margin: 3rem 2rem;
      padding-left: 5rem;
      p {
        font-size: 1.4rem;
        line-height: 1.6;
      }
    }
    h2 {
      margin-bottom: 1rem;
    }
    ol,
    ul,
    p {
      &:not([class]) {
        margin-bottom: 2rem;
      }
    }
    .streamer {
      margin: 3rem;
    }
  }
}
