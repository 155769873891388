@use 'sass:map';

.page--error .jumbotron {
  .jumbotron__section {
    padding: 1rem;
    > .row > .column:first-child {
      justify-content: flex-start;
    }
  }
  .linklist {
    a {
      &:not(.button) {
        color: $white;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
      &::before {
        font-size: 24px;
        left: -.5rem;
        top: .25rem;
      }
    }
  }
  &.jumbotron--404 {
    $jumbotron-space-bg-color: #260f45;
    background: $jumbotron-space-bg-color url('../images/deadlyduck/illustrations/earth-space-stars.svg') no-repeat 10% center;
    background-size: cover;
  }
  &.jumbotron--forbidden {
    $jumbotron-road-bg-color: #694b31;
    background: $jumbotron-road-bg-color url('../images/deadlyduck/illustrations/road.svg') no-repeat center top;
    background-size: cover;
  }
  &.jumbotron--upgrade {
    background: linear-gradient(to bottom, $info-color, map.get($foundation-palette-darkened, 'info'));
    .mountains {
      bottom: -5%;
      max-width: none;
      opacity: .7;
      position: absolute;
      right: -178px;
      width: 775px;
    }
    .knowly {
      float: left;
      margin-left: -45%;
      margin-top: 5%;
      position: relative;
      transform: rotate(15deg);
    }
  }
}

.dev-info {
  background: rgba($black, .2);
  border-radius: 10px;
  display: inline-block;
  font-family: 'Courier New', Courier, 'Lucida Sans Typewriter', 'Lucida Typewriter', monospace;
  font-size: 1.25rem;
  font-weight: $global-weight-bold;
  padding: 2rem;
  .media-object {
    margin-bottom: 0;
  }
}
