.prefix,
.postfix {
  border: $input-border;
  border-style: solid;
  border-width: 0 0 1px;
  display: block;
  font-size: .8667rem;
  height: 2.5rem;
  line-height: 2.5rem;
  overflow: visible;
  padding-bottom: 0;
  padding-top: 0;
  position: relative;
  text-align: center;
  width: 100%;
  z-index: 1;
}

.prefix {
  text-align: left;
}

.postfix {
  display: flex;
  flex-flow: row wrap;
  text-align: right;
  &__column-input {
    flex: 2;
  }
  &__column-label {
    flex: 0;
  }
  &__label {
    padding-left: .5rem;
  }
  input,
  select {
    width: 100%;
  }
}

span,
label {
  &.prefix,
  &.postfix {
    font-size: rem-calc(15);
  }
}
