$textarea-code-text-shadow: 0 1px rgba(0 0 0 / 30%);

// Base input styles
input:not([type]),
input[type='text'],
input[type='password'],
input[type='date'],
input[type='datetime'],
input[type='datetime-local'],
input[type='month'],
input[type='week'],
input[type='email'],
input[type='number'],
input[type='search'],
input[type='tel'],
input[type='time'],
input[type='url'],
input[type='color'],
textarea,
select {
  margin-bottom: 0;
  &,
  &:focus {
    border-width: 0 0 1px;
  }
  &[disabled] {
    background: transparent;
    opacity: .5;
  }
  &.round {
    border-radius: $global-rounded;
  }
}

input,
textarea,
select {
  font-family: $input-font-family;
  font-weight: $input-font-weight;
  &.large {
    font-size: rem-calc(20);
    height: 4rem;
  }
}

textarea.large {
  line-height: 1.6;
}

select {
  /* stylelint-disable-next-line declaration-no-important */
  appearance: menulist !important;
  background-image: none;
  &,
  &:focus {
    border-width: 1px;
  }
  &::-ms-expand {
    display: block;
  }
  option[value=''] {
    background-color: $white-smoke;
  }
}

textarea {
  border-width: 1px;
  font-size: rem-calc(13);
  font-weight: normal;
  resize: vertical;
  &,
  &:focus {
    border-width: 1px;
  }
  &.textarea--code {
    background: $black;
    color: $white-smoke;
    font-family: $font-family-monospace;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-shadow: $textarea-code-text-shadow;
    white-space: pre;
    word-break: normal;
    word-spacing: normal;
    word-wrap: normal;
  }
}

select,
input[type='number'] {
  min-width: 100px;
}

.help-text {
  font-size: rem-calc(12);
  margin: .25rem 0 0;
  &:empty {
    display: none;
  }
}

.error {
  input[type='checkbox'],
  input[type='radio'] {
    margin-bottom: 0;
  }
}

html:not(.no-js) .placeholder {
  display: none;
}

/* iOs prevent input zoom */

/* stylelint-disable-next-line media-feature-name-no-vendor-prefix */
@supports (-webkit-overflow-scrolling: touch) {
  select,
  textarea,
  input {
    /* stylelint-disable-next-line declaration-no-important */
    font-size: 16px !important;
  }
}
