@use 'sass:map';

$img-margin: (
  small: 1rem,
  medium: 3rem,
);

.links {
  float: left;
  margin: 0 map.get($img-margin, small) map.get($img-margin, small) 0;
}

.rechts {
  float: right;
  margin: 0 0 map.get($img-margin, small) map.get($img-margin, small);
}

.geen,
.full-width {
  display: block;
  margin: map.get($img-margin, small) auto;
}

figure {
  margin: map.get($img-margin, small) 0;
}

figcaption {
  font-size: .8rem;
  margin: .5rem 0 0;
  text-align: center;
}

@include breakpoint(medium) {
  .links {
    margin: 0 map.get($img-margin, medium) map.get($img-margin, medium) 0;
  }

  .rechts {
    margin: 0 0 map.get($img-margin, medium) map.get($img-margin, medium);
  }

  .geen,
  .full-width {
    margin: map.get($img-margin, medium) auto;
  }

  figure {
    margin: map.get($img-margin, medium) 0;
  }

  figcaption {
    font-size: .9rem;
  }
}
