/*! rtl:begin:ignore */
.illustration--scrum {
  &__postit > g {
    animation: animation-float-fall linear 5s forwards;
    transform-origin: left top;
  }
  &__postit-shadow {
    animation: animation-remove-drop-shadow linear 5s forwards;
  }
  &__knowly-and-chair {
    animation: animation-bouncy-chair linear 1.5s infinite;
    transform: translate3d(0, 3px, 0);
  }
}

/*! rtl:end:ignore */
