.pagefooter {
  background-color: $pagefooter-bg-color;
  color: $base;
  overflow-y: hidden;
  position: relative;
  &::after {
    background: $pagefooter-bg-color;
    content: '';
    display: block;
    height: 99999px;
    left: 0;
    position: absolute;
    right: 0;
    top: 100%;
    width: 100%;
  }
  &__main {
    padding: 2rem 0;
  }
  &__sub {
    border-top: 1px dashed $pagefooter-sub-border-color;
    padding: 1rem 0;
    position: relative;
    .has-tip {
      color: $base;
    }
    a {
      color: $white;
    }
    &-list {
      margin: 0 -1rem;
      li {
        color: $base;
        display: inline-block;
        font-size: rem-calc(13);
        padding: 0 1rem;
      }
    }
  }
  &__title {
    color: $white;
    display: block;
    font-family: $header-font-family;
    font-size: rem-calc(18);
    font-weight: $header-font-weight;
  }
  &__copyright {
    margin-right: .5rem;
  }
  .menu {
    &.vertical {
      margin-bottom: 1.5rem;
    }
    li a {
      color: $base;
      display: inline-block;
      padding-left: 0;
      padding-right: 0;
      &:hover {
        color: $white;
      }
    }
    .mdi {
      margin: 0 .5rem 0 0;
      &::before {
        vertical-align: middle;
      }
    }
  }
  small {
    display: block;
    margin-top: .25rem;
  }
  &__gdpr-link {
    align-items: center;
    color: $white;
    display: flex;
    margin-right: 3rem;
    svg {
      margin-right: 1rem;
      opacity: .6;
      vertical-align: baseline;
    }
    &:hover,
    &:focus {
      color: $white;
      svg {
        opacity: 1;
      }
    }
  }
  &__gdpr-small {
    display: inline-block !important;
    margin: 0 !important;
  }
  &__gdpr-link-subtext {
    display: block;
  }
  &__iso-image {
    width: 200px;
  }
}

@include breakpoint(small only) {
  .pagefooter .column + .column {
    margin-top: 1.5rem;
  }
}

@include breakpoint(medium) {
  .pagefooter {
    &__main {
      padding: 8rem 0;
    }
  }
}
