.meta {
  color: $jumbo;
  &__part {
    margin: 1rem 0;
  }
  &__title {
    display: block;
  }
  &--bottom {
    padding-top: 1rem;
  }
  &--author-side {
    margin-left: calc(80px + #{$mediaobject-section-padding});
  }
  &--horizontal {
    display: flex;
    flex-flow: row nowrap;
    margin: 0 -1rem 1rem;
    .meta__part {
      margin: 0;
      padding: 0 1rem;
    }
  }
  &--small {
    font-size: $small-font-size;
  }
}
