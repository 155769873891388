@use 'sass:color';

.card {
  background-color: $card-background;
  border-radius: $card-border-radius;
  display: block;
  margin-bottom: $card-margin-bottom;
  overflow: visible;

  &__header,
  &__subscriptionheader,
  &__footer {
    @include clearfix;
    background-color: $card-divider-background;
    padding: .5rem 1rem;

    &--light {
      background-color: $card-background;
    }
  }

  &__header,
  &__subscriptionheader {
    border-bottom: 1px solid $card-border-color;
    border-radius: $card-border-radius $card-border-radius 0 0;
    position: relative;
    transition: background-color .3s ease;
  }

  &__footer {
    border-top: 1px solid $card-border-color;
    font-size: rem-calc(14);

    .inline-list {
      font-size: rem-calc(14);
      margin-bottom: 0;
    }

    .readmore {
      display: block;
    }

    &-button.button {
      /* stylelint-disable-next-line declaration-no-important */
      border-radius: 0 0 $card-border-radius $card-border-radius !important;
      padding-bottom: 1rem;
      padding-top: 1rem;
      width: 100%;
    }
  }

  &__link {
    &:hover,
    &:focus {
      .card__link-text {
        text-decoration: underline;
      }
    }

    &:focus {
      .card__header {
        background-color: $silver;
      }
    }
  }

  &__title {
    h1,
    h2,
    h3 {
      font-size: rem-calc(18);
      margin: 0;
    }

    h4,
    h5,
    h6 {
      margin: 0;
    }
  }

  &__body {
    background-color: $card-background;
    max-width: 100%;
    overflow-wrap: break-word;
    width: 100%;

    &:first-child {
      border-radius: $card-border-radius $card-border-radius 0 0;
    }

    &:last-child {
      border-radius: 0 0 $card-border-radius $card-border-radius;
    }

    &:first-child:last-child {
      border-radius: $card-border-radius;
    }
  }

  &__body,
  &__inner {
    display: block;
    padding: $card-padding;
    position: relative;
  }

  &__illustration {
    display: inline-block;
    margin-left: 2rem;
    position: relative;
    width: 100px;

    img {
      bottom: -4rem;
      position: absolute;
    }
  }

  &__illustration-link {
    background-color: color.scale($body-background, $lightness: -5%);
    display: block;
    overflow: hidden;
    padding: 0;
    position: relative;
    width: 100%;

    img {
      bottom: 0;
      left: 0;
      padding: 0 4rem;
      position: absolute;
    }

    &::after {
      content: '';
      display: block;
      padding-top: 56.25%;
    }
  }

  &__nav {
    margin: 0;
  }

  &--0 {
    border: 1px solid $card-border-color;
    border-radius: $card-border-radius;
  }

  &--1 {
    box-shadow: $card-1-shadow;
    transition: all .3s cubic-bezier(.25, .8, .25, 1);
  }

  &--2 {
    box-shadow: $card-2-shadow;
  }

  &--3 {
    box-shadow: $card-3-shadow;
  }

  &--4 {
    box-shadow: $card-4-shadow;
  }

  &--5 {
    box-shadow: $card-5-shadow;
  }

  &--gray {
    &,
    .card__body {
      background-color: $snow;
    }
  }

  &--horizontal {
    .card__illustration-link {
      align-items: flex-end;
      display: flex;
      justify-content: center;
      padding-bottom: 0;

      img {
        bottom: auto;
        left: auto;
        max-height: 175px;
        padding: 1rem 1rem 0;
        position: relative;
      }

      &::after {
        padding: 0;
      }
    }

    @include breakpoint(medium) {
      display: flex;
      flex-flow: row nowrap;
      .card__header {
        border-radius: $card-border-radius 0 0 $card-border-radius;
        flex: 0 0 33.3333%;
        padding: 0;
      }
      .card__body {
        flex: 0 0 66.6667%;

        &:first-child {
          border-radius: $card-border-radius 0 0 $card-border-radius;
        }

        &:last-child {
          border-radius: 0 $card-border-radius $card-border-radius 0;
        }
      }
    }
  }

  @include breakpoint(small only) {
    &-list {
      margin-bottom: -2rem;

      &,
      &__item {
        float: none;
      }
    }
  }
}

@include breakpoint(medium) {
  .card {
    margin-bottom: 2rem;

    &__header {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      padding: 1rem 2rem;

      &--small {
        padding: .5rem 1rem;
      }
    }

    &__subscriptionheader {
      align-items: center;
      padding: 1rem 2rem;
    }

    &__title {
      margin-right: 1rem;

      h1,
      h2,
      h3 {
        display: inline-block;
        font-size: rem-calc(22);
      }
    }

    &__subtitle {
      display: inline-block;
      margin: 0 0 0 1rem;

      .questiontype-icon {
        display: inline-block;
        position: relative;
        top: -2px;
      }
    }

    &__nav {
      float: left;
    }

    &__body {
      padding: $card-padding * 2;
    }
  }
  .card-list {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    list-style: none;
    margin-bottom: -3.5rem;

    &__item {
      display: flex;
      flex: 0 0 auto;
      flex-flow: column nowrap;
      margin-bottom: 1.5rem;
      text-align: center;

      .card {
        align-items: flex-start;
        display: flex;
        margin-left: .5rem;
        margin-right: .5rem;

        .card__body,
        .card__inner,
        .card__footer,
        .card__footer-button {
          flex: 1 1 auto;
        }

        .card__footer,
        .card__footer-button {
          margin-top: auto;
        }
      }
    }

    &--margin-bottom {
      margin-bottom: 0;
    }
  }
}

@include breakpoint(large) {
  .card__body.cmscontent {
    padding: $card-padding * 4;
  }
}

@include breakpoint(small only) {
  .card {
    &__illustration {
      display: none;
    }
  }
}
