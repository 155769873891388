@use 'sass:color';

@mixin raised-button-style($color) {
  border-bottom: 5px solid color.scale($color, $lightness: -20%);
}

@mixin hollow-button-style($color) {
  background: none;
  border: 1px solid $color;
  color: $color;
  &:hover,
  &:focus {
    background: none;
    border-color: color.scale($color, $lightness: $button-function-factor);
    color: color.scale($color, $lightness: $button-function-factor);
  }
}

/* stylelint-disable declaration-no-important */
// reset global button styles
button {
  @include button-base;
  /* stylelint-disable-next-line */
  background-color: initial;
  cursor: pointer;
  font-weight: $global-weight-bold;
  padding: 0;
}

.button {
  border: 0;
  font-weight: $global-weight-bold;
  &.round {
    border-radius: $global-rounded;
  }
  &.tiny {
    padding: .5333rem 1rem .6rem;
  }
  &.small {
    padding: .7rem 1.3rem .7333rem;
  }
  &.large {
    padding: 1.4rem 2.5rem;
  }
  &.xxlarge {
    font-size: 1.2rem;
    padding: 1.4rem 2.8rem;
  }
  &.expanded {
    width: 100%;
  }
  &.secondary {
    &,
    &:hover,
    &:focus {
      color: $button-color;
    }
    .jumbotron & {
      background-color: color.scale($secondary-color, $lightness: $button-function-factor);
      &:hover,
      &:focus {
        background-color: color.scale($secondary-color, $lightness: 2 * $button-function-factor);
      }
    }
  }
  &.success,
  &.alert,
  &.warning {
    &,
    &:hover,
    &:focus {
      color: $button-color;
    }
  }
  &.info {
    @include button-style(
      $background: $info-color,
      $background-hover: color.scale($info-color, $lightness: $button-function-factor)
    );
  }
  &.sub {
    @include button-style(
      $background: $button-sub-bg-color,
      $background-hover: $button-sub-bg-color-hover,
      $color: $header-color
    );
  }
  &.link {
    background: none;
    border: 0;
    color: $anchor-color;
    font-weight: $global-weight-normal;
    padding: 0;
    text-align: left;
    &:hover,
    &:focus {
      background: none;
      color: $anchor-color-hover;
    }
    &.primary {
      color: $primary-color;
      &:hover,
      &:focus {
        color: color.scale($primary-color, $lightness: $button-function-factor);
      }
    }
    &.secondary {
      color: $secondary-color;
      &:hover,
      &:focus {
        color: color.scale($secondary-color, $lightness: $button-function-factor);
      }
    }
    &.text {
      color: $body-font-color;
      &:hover,
      &:focus {
        color: color.scale($body-font-color, $lightness: $button-function-factor);
      }
    }
    .mdi {
      margin: 0 .5rem 0 0;
    }
  }
  &.circle {
    border-radius: 9999px;
    padding: 1rem;
    &,
    * {
      line-height: 1;
    }
    .mdi-24px {
      &,
      &::before {
        height: 24px;
        line-height: 1;
        position: relative;
      }
    }
  }
  &.hollow {
    @include hollow-button-style($primary-color);
    &.primary {
      @include hollow-button-style($primary-color);
    }
    &.secondary {
      @include hollow-button-style(color.scale($secondary-color, $lightness: -6%));
    }
    &.success {
      @include hollow-button-style(color.scale($success-color, $lightness: -6%));
    }
    &.info {
      @include hollow-button-style($info-color);
    }
    &.warning {
      @include hollow-button-style($warning-color);
    }
    &.alert {
      @include hollow-button-style($alert-color);
    }
    &.sub {
      @include hollow-button-style($header-color);
    }
    &.light {
      @include hollow-button-style($white);
      .jumbotron--primary & {
        &:hover,
        &:focus {
          background-color: $white;
          color: $secondary-color;
        }
      }
    }
  }
  &.raised {
    @include raised-button-style($primary-color);
    &.primary {
      @include raised-button-style($primary-color);
    }
    &.secondary {
      @include raised-button-style($secondary-color);
    }
    &.success {
      @include raised-button-style($success-color);
    }
    &.info {
      @include raised-button-style($info-color);
    }
    &.warning {
      @include raised-button-style($warning-color);
    }
    &.alert {
      @include raised-button-style($alert-color);
    }
    &.sub {
      @include raised-button-style($button-sub-bg-color);
    }
  }
  &.split {
    background: none;
    border-radius: 0;
    font-size: 0;
    padding: 0;
    &.round {
      border-radius: 0;
      .button {
        margin: 0;
        width: auto;
        &:first-child {
          border-radius: 1000px 0 0 1000px;
          border-right: 5px solid $white;
          padding-left: 1.5rem;
          padding-right: 1rem;
        }
        &:last-child {
          border-radius: 0 1000px 1000px 0;
          padding-left: 1rem;
          padding-right: 1.5rem;
        }
      }
    }
  }
  &.clear {
    background-color: transparent;
    border: 0 !important;
    color: $anchor-color;
    &,
    &:hover,
    &:focus {
      color: $anchor-color-hover;
      &,
      &.disabled,
      &[disabled] {
        border-color: transparent;
      }
    }
    &--narrow {
      padding-left: .25rem;
      padding-right: .25rem;
    }

    @include hollow-button-style($primary-color);
    &.primary {
      @include hollow-button-style($primary-color);
    }
    &.secondary {
      @include hollow-button-style($secondary-color);
    }
    &.success {
      @include hollow-button-style($success-color);
    }
    &.info {
      @include hollow-button-style($info-color);
    }
    &.warning {
      @include hollow-button-style($warning-color);
    }
    &.alert {
      @include hollow-button-style($alert-color);
    }
    &.sub {
      @include hollow-button-style($header-color);
    }
  }
  &[disabled] {
    cursor: not-allowed;
  }
  &[data-label-beta] {
    padding-right: .7rem;
    &::after {
      @include beta-label;
      content: attr(data-label-beta);
      display: inline-block;
      margin: -.25rem 0 -.25rem .5rem;
      position: relative;
    }
  }

  // Icons in buttons
  .mdi {
    color: inherit;
    display: inline-block;
    font-size: 0;
    line-height: 1;
    position: relative;
    vertical-align: middle;
    width: 24px;
    &::before {
      left: 0;
      line-height: 0;
      position: absolute;
      top: 0;
    }
  }
  &--icon-left {
    .mdi {
      &::before {
        left: -.75rem;
      }
    }
    &.small {
      .mdi {
        &::before {
          left: -.5rem;
        }
      }
    }
  }

  &--plus {
    &.large {
      height: 54px;
      line-height: 0;
      width: 54px;
    }
    .mdi {
      transform: none;
      transition: all 200ms linear;
    }
    &.open .mdi {
      transform: rotate(90deg);
    }
  }
  &--loading {
    color: transparent !important;
    &::before {
      @include loader-base($button-color, 18px);
    }
    &.sub::after {
      color: $button-color-alt;
    }
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

// Styling fix for a close-button in a modal that is not a cross in the upper-right corner
.button.close-reveal-modal,
.reveal-modal__button--prev {
  position: relative;
  right: auto !important;
  top: auto !important;
}

@include breakpoint(medium) {
  .button.xxlarge {
    font-size: 1.6rem;
  }
}

@include breakpoint(small only) {
  .button {
    padding: .75rem 1.3333rem;
    &--expanded-on-small {
      width: 100%;
    }
  }
}

/* stylelint-enable */
