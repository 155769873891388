@import '~easy-lms-base-styles/settings',
  'deadlyduck/foundation-components--public',
  'deadlyduck/mixins',
  'deadlyduck/components/accessibility',
  'deadlyduck/components/accordion',
  'deadlyduck/components/anchor',
  'deadlyduck/components/articlenav',
  'deadlyduck/components/author',
  'deadlyduck/components/avatar',
  'deadlyduck/components/blockquote',
  'deadlyduck/components/breadcrumbs',
  'deadlyduck/components/breakout',
  'deadlyduck/components/card',
  'deadlyduck/components/close-button',
  'deadlyduck/components/clouds',
  'deadlyduck/components/cmscontent',
  'deadlyduck/components/contentcontainer',
  'deadlyduck/components/crisp',
  'deadlyduck/components/css-columns',
  'deadlyduck/components/cta-bar',
  'deadlyduck/components/customers',
  'deadlyduck/components/dropdown',
  'deadlyduck/components/dual-cta',
  'deadlyduck/components/faq',
  'deadlyduck/components/features',
  'deadlyduck/components/float-classes',
  'deadlyduck/components/focus',
  'deadlyduck/components/fonts',
  'deadlyduck/components/grid',
  'deadlyduck/components/header',
  'deadlyduck/components/icons',
  'deadlyduck/components/images',
  'deadlyduck/components/jumbotron',
  'deadlyduck/components/knowly',
  'deadlyduck/components/knowly-eyes',
  'deadlyduck/components/label',
  'deadlyduck/components/label-icon',
  'deadlyduck/components/language-menu',
  'deadlyduck/components/lists',
  'deadlyduck/components/media-object',
  'deadlyduck/components/menu',
  'deadlyduck/components/meta',
  'deadlyduck/components/off-canvas',
  'deadlyduck/components/pagefooter',
  'deadlyduck/components/panel',
  'deadlyduck/components/postcard',
  'deadlyduck/components/preview-browser',
  'deadlyduck/components/price',
  'deadlyduck/components/pricing',
  'deadlyduck/components/hello-bar',
  'deadlyduck/components/register-cta',
  'deadlyduck/components/responsive-embed',
  'deadlyduck/components/reveal',
  'deadlyduck/components/review-badge',
  'deadlyduck/components/skip-link',
  'deadlyduck/components/slick-slider',
  'deadlyduck/components/snackbar',
  'deadlyduck/components/speech-bubble',
  'deadlyduck/components/star-rating',
  'deadlyduck/components/subnav',
  'deadlyduck/components/subscription-list',
  'deadlyduck/components/svg',
  'deadlyduck/components/table',
  'deadlyduck/components/team',
  'deadlyduck/components/tooltip',
  'deadlyduck/components/top-bar',
  'deadlyduck/components/typography',
  'deadlyduck/components/forms/base',
  'deadlyduck/components/forms/button',
  'deadlyduck/components/forms/error',
  'deadlyduck/components/forms/fieldset',
  'deadlyduck/components/forms/formcontrol',
  'deadlyduck/components/forms/materialize',
  'deadlyduck/components/forms/placeholder',
  'deadlyduck/components/forms/prefix',
  'deadlyduck/components/forms/switch',
  'deadlyduck/components/forms/toggles',
  'deadlyduck/components/page-templates/error',
  'deadlyduck/components/page-templates/about-us',
  'deadlyduck/components/page-templates/customers',
  'deadlyduck/components/page-templates/productpage',
  'deadlyduck/components/page-templates/offboarding',
  'deadlyduck/components/animations-effects/index',
  'illustrations/index',
  'deadlyduck/alignment-helpers';

html {
  overflow-x: hidden;
}

body {
  background: $body-background;
}

main:focus {
  @include disable-mouse-outline;
}

.row--expanded {
  max-width: 100%;
}

[data-show-if],
.js .js-hide,
.hidden {
  display: none;
}

.js [data-show-if][style*='visible'] {
  display: block;
}

.m-scene {
  min-height: 100%;
}

.show-for-sr-large-up {
  @include breakpoint(large) {
    @include element-invisible;
  }
}

.show-for-sr-medium-up {
  @include breakpoint(medium) {
    @include element-invisible;
  }
}

.show-for-sr-medium-only {
  @include breakpoint(medium only) {
    @include element-invisible;
  }
}

.show-for-sr-small-only {
  @include breakpoint(small only) {
    @include element-invisible;
  }
}

@include breakpoint(small only) {
  .small-space-columns > .column + .column {
    margin-top: 1rem;
  }
}
