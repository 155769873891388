@import '~@mdi/font/scss/variables',
  '~@mdi/font/scss/functions';

$breadcrumbs-item-separator-item: mdi('chevron-right');
$breadcrumbs-item-separator-item-rtl: mdi('chevron-left');

@include foundation-breadcrumbs;

.breadcrumbs {
  li {
    line-height: 0;
    &::after {
      font-family: $mdi-font-name;
    }
    &:not(:last-child) a {
      &:hover,
      &:focus {
        color: $breadcrumbs-item-color-current;
      }
    }
  }
  .current a {
    color: $breadcrumbs-item-color-current;
  }
  a {
    font-weight: bold;
    line-height: 1;
    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
  &--dark {
    li {
      a,
      &:not(:last-child) a:hover,
      &:not(:last-child) a:focus,
      &:not(:last-child)::after,
      &::after {
        color: $oil;
      }
      &.current a {
        color: $jumbo;
      }
    }
  }
}

.top-bar .breadcrumbs {
  margin: 0 1.5rem;
}

.jumbotron {
  .breadcrumbs-container {
    position: relative;
    width: 100%;
    z-index: 2;
    .breadcrumbs {
      margin: 0;
      padding: 1rem 0;
    }
  }
  &--primary {
    .breadcrumbs-container {
      background: none;
      box-shadow: none;
    }
  }
}

@include breakpoint(medium) {
  .breadcrumbs {
    &-container {
      @include clearfix;
      background-color: $white;
      box-shadow: $card-1-shadow;
    }
  }
}
