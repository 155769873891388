.contentcontainer {
  @include disable-mouse-outline;
  display: inline-block;
  padding: 1rem 0;
  width: 100%;
  &__section {
    margin-bottom: 1rem;
  }
  &--white {
    background: $white;
  }
}

@include breakpoint(medium) {
  .contentcontainer {
    padding: 3rem 0;
    &__section {
      margin-bottom: 3rem;
    }
  }
}

@include breakpoint(small only) {
  .contentcontainer {
    padding-bottom: 2rem;
  }
}
