.align-bottom {
  &,
  .column & {
    justify-content: flex-end;
  }
}

.align-center {
  &,
  .column & {
    justify-content: center;
  }
}

.column,
.columns {
  position: relative;
}

.row--fullwidth {
  max-width: none;
}

@include breakpoint(medium) {
  .row--flex {
    display: flex;
    flex: 1 1 auto;
    flex-flow: row nowrap;
    > .column {
      display: flex;
      flex-direction: column;
    }
  }
  .row--reverse {
    flex-direction: row-reverse;
  }
  .row--flex-equal-heights {
    display: flex;
    flex-flow: row wrap;
    &::before,
    &::after {
      display: none;
    }
    .column {
      display: flex;
      flex-flow: column nowrap;
      > .card,
      > .panel {
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;
        height: 100%;
        > .card__inner {
          flex: 2;
        }
      }
    }
  }

  .grid-container {
    display: grid;
    &--content {
      grid: 'left . right-top' min-content 'left . right-bottom' 1fr / 58.33% 8.34% 33.33%;
    }
  }

  .row.medium-uncollapse > .column,
  .row.medium-uncollapse > .columns {
    padding-left: $column-gutter * .5;
    padding-right: $column-gutter * .5;
  }
}

@include breakpoint(medium only) {
  .row.medium-only-uncollapse > .column,
  .row.medium-only-uncollapse > .columns {
    padding-left: $column-gutter * .5;
    padding-right: $column-gutter * .5;
  }
}

.grid-right-top {
  grid-area: right-top;
  height: auto;
}

.grid-left {
  grid-area: left;
}

.grid-right-bottom {
  grid-area: right-bottom;
}
