.cta-bar {
  padding: 2rem 0 2.5rem;
  position: relative;
  &__actions {
    margin: 0;
    max-width: 100%;
    position: relative;
  }
  &__knowly {
    bottom: -38px;
    display: none;
    margin: 0 0 0 4rem;
    position: absolute;
    right: -160px;
    width: 120px;
  }
  &__choose {
    padding: 0 .5rem;
  }
  &--white {
    background-color: $white;
  }
  &--left {
    .cta-bar__knowly {
      left: -210px;
      right: auto;
    }
  }
}

@media #{$medium-up} {
  .cta-bar {
    &__knowly {
      display: inline-block;
    }
    &__actions {
      display: inline-block;
    }
  }
}
