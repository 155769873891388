/* stylelint-disable declaration-no-important */
@mixin placeholder {
  color: $input-placeholder-color !important;
  font-weight: normal !important;
}

input::placeholder,
textarea::placeholder,
.mce-edit-area label {
  @include placeholder;
}

// Materialize placeholders
html:not(.no-js) .formcontrol--materialize {
  input,
  textarea {
    &::placeholder {
      @include placeholder;
      color: $white !important;
    }
    &:focus {
      &::placeholder {
        @include placeholder;
      }
    }
  }
}

/* stylelint-enable */
