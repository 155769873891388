.formcontrol__error {
  color: $input-error-color;
  display: none;
  font-size: $input-error-font-size;
  font-weight: $input-error-font-weight;
  margin: 0;
  padding: .5rem 0 0;
}

.error > .formcontrol__error,
.formcontrol.error .formcontrol__error,
.error .formcontrol__error {
  display: block;
}

fieldset {
  &.error > .formcontrol__error {
    @include panel($alert-color, scale-color($alert-color, $lightness: -17.5%));
    border-radius: $global-radius;
    clear: both;
    float: none;
    font-size: 1rem;
    font-style: normal;
    margin: 2rem 0 1rem;
    padding: 1rem;
    ~ .answer-list {
      margin-top: -1rem;
    }
    &::before {
      @include materialiconize('alert', 24);
      margin-right: 1rem;
    }
  }
}

.error-summary { /* Yii error summary */
  @include panel($alert-color, scale-color($alert-color, $lightness: -17.5%));
  border-radius: $global-radius;
  display: block;
  padding: $global-padding;
  ul:last-child {
    margin-bottom: 0;
  }
}
