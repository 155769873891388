@keyframes animation-slide-in-down {
  0% {
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }
  100% {
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }
}
