@use 'sass:map';
@use 'sass:color';

.hello-bar {
  background-color: map.get($foundation-palette-lightened, 'primary');
  font-size: $small-font-size;
  font-weight: bold;
  overflow: hidden;
  padding: 0 1rem;
  text-align: center;
  a {
    color: color.scale(map.get($foundation-palette, 'primary'), $lightness: -15%);
    display: inline-block;
    padding: .25rem .5rem;
    &::before,
    &::after {
      background-image: url('../images/deadlyduck/illustrations/eknowlycon.svg');
      background-repeat: no-repeat;
      content: '';
      display: inline-block;
      height: 25px;
      margin: -12px 1rem;
      position: relative;
      transition: all .25s ease-in-out;
      width: 30px;
    }
    &::before {
      top: -16px;
      transform: rotate(180deg) scaleX(-1);
    }
    &::after {
      bottom: -10px;
      transform: scaleX(-1);
    }
    &:hover,
    &:focus {
      color: color.scale(map.get($foundation-palette, 'primary'), $lightness: -20%);
      &::before {
        top: -13px;
      }
      &::after {
        bottom: -7px;
      }
    }
  }
}
