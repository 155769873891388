.accordion {
  &-item {
    &.is-active > .accordion-title {
      background-color: $snow;
      &::before {
        @include materialiconize('chevron-down');
        transform: rotate(180deg);
      }
    }
    &:first-child .accordion-title {
      border-radius: $global-radius $global-radius 0 0;
    }
    &:last-child .accordion-title {
      border-radius: 0 0 $global-radius $global-radius;
    }
  }
  &-title {
    font-weight: $global-weight-bold;
    line-height: 1.2;
    position: relative;
    &::before {
      @include materialiconize('chevron-down');
      margin: -12px 0 0;
      position: absolute;
      right: 1rem;
      top: 50%;
      transform: none;
      transition: all 200ms ease;
    }
  }
  &-title,
  &-content {
    border: 1px solid $gainsboro;
    margin-bottom: -1px;
  }
  &--minimal {
    background: none;
    border-bottom: 1px solid rgba($black, .1);
    margin: 2rem 0;
    .accordion {
      &-item {
        border-top: 1px solid rgba($black, .1);
        &:last-child > .accordion-content,
        &:last-child:not(.is-active) > .accordion-title {
          border-bottom: 0;
        }
      }
      &-title {
        background: none;
        border: 0;
        border-bottom-color: rgba($black, .1);
        color: $primary-color;
        font-size: 1.1rem;
        padding: 1.5rem 3.5rem 1.5rem 0;
      }
      &-content {
        background: none;
        border: 0;
        padding: 0 0 1.5rem;
        > *:last-child {
          margin-bottom: 0;
        }
      }
    }

    &.accordion .accordion-item.is-active > .accordion-title {
      &,
      &:hover,
      &:focus {
        background: none;
        border-bottom: 0;
        color: $header-color;
      }
    }
  }
}

.breakout-container-alternate .breakout:nth-child(even) {
  .accordion--minimal .accordion-item:not(.is-active) .accordion-title {
    color: $secondary-color;
  }
}

@include breakpoint(large) {
  .accordion {
    .accordion-item > .accordion-content {
      padding: 2rem;
    }
    &--minimal .accordion-item > .accordion-content {
      padding: 0 0 1.5rem;
    }
  }
}
