@use 'sass:color';

$base-size: 18px;
$toggle-color: $primary-color;
$toggle-shadow-0: 0 0 0 0 color.adjust($toggle-color, $lightness: 10%);
$toggle-shadow-100: 0 0 0 15px rgba(color.adjust($toggle-color, $lightness: 10%), .4);

.toggle {
  &__container {
    height: $base-size;
    position: relative;
    width: $base-size;
    > * {
      left: 0;
      position: absolute;
      top: 0;
    }
  }
}

input[type='radio'],
input[type='checkbox'] {
  appearance: none;
  margin: 0;
  opacity: 0;
  outline: none;
  outline-width: 0;
  z-index: 2;
  &:focus {
    + .checkbox,
    + .radiobutton {
      animation: press .4s forwards;
    }
  }
}

.checkbox,
.radiobutton {
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-backface-visibility: hidden;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  display: block;
  margin: 0;
  position: relative;
  vertical-align: middle;
  width: $base-size;
  z-index: 1;
  &::before,
  &::after {
    content: '';
  }
  &::after {
    border: 2px solid $form-label-color;
    display: block;
    height: $base-size;
    margin-right: 5px;
    transition: 240ms ease-in-out;
    width: $base-size;
  }
}

input[type='checkbox'] {
  + .checkbox::after {
    background-color: $white;
    border-radius: 2px;
    z-index: 999;
  }
  &:checked {
    + .checkbox {
      &::after {
        background-color: $toggle-color;
        border-color: $toggle-color;
      }
      &::before {
        border: 2px solid $white;
        border-left-width: 0 #{'/*! rtl:ignore */'};
        border-top-width: 0;
        content: '';
        display: block;
        height: 12px;
        left: 6px #{'/*! rtl:ignore */'};
        position: absolute;
        top: 2px;
        transform: rotate(45deg) #{'/*! rtl:ignore */'};
        width: 6px;
      }
    }
  }
}

input[type='radio'] {
  + .radiobutton {
    &::before {
      background-color: $toggle-color;
      border-radius: 50%;
      display: block;
      height: $base-size;
      left: 0;
      position: absolute;
      top: 0;
      transform: scale(0);
      transform-origin: center;
      transition: 240ms ease-in-out;
      width: $base-size;
    }
    &::after {
      border-radius: 50%;
    }
  }

  &:checked {
    + .radiobutton {
      &::before {
        transform: scale(.45);
      }
      &::after {
        border-color: $toggle-color;
        transform: translate3d(0, 0, 0);
      }
    }
  }
}

// disabled
input[disabled] {
  ~ .checkbox,
  ~ .radiobutton {
    &::after {
      background-color: $white-smoke;
      border-color: $iron;
      cursor: not-allowed;
    }
  }
  &[checked] {
    ~ .checkbox,
    ~ .radiobutton {
      &::before {
        z-index: 1;
      }
      &::after {
        background-color: $white-smoke;
        border-color: $iron;
      }
    }
    ~ .checkbox::before {
      border-color: $base;
    }
    ~ .radiobutton::before {
      background-color: $base;
      transform: translate3d(0, 0, 0) scale(.45);
    }
  }
}

@keyframes press {
  0% {
    box-shadow: $toggle-shadow-0;
  }

  100% {
    box-shadow: $toggle-shadow-100;
  }
}
