$top-bar-shadow: rgba($white, .2);
$topbar-link-padding: ($topbar-height * .25);
/* stylelint-disable selector-max-type */
/* stylelint-disable declaration-no-important */

.top-bar {
  background: $topbar-background;
  height: $topbar-height;
  overflow: visible;
  padding-bottom: 1px;
  z-index: 1002;
  ul {
    background: none;
  }
  .dropdown-pane {
    background-color: $white;
  }
  &__logo {
    float: left;
    height: $topbar-height;
    padding: 8px .8rem 0;
    img {
      height: 42px;
      max-width: 42px;
      width: 100%;
    }
  }
  &-left,
  &-right {
    height: 100%;
    > .menu > li {
      display: flex;
      justify-content: center;
      > a {
        border-radius: 1000px;
        color: $white;
        font-family: $header-font-family;
        font-weight: $global-weight-bold;
        height: auto;
        line-height: 36px !important;
        margin: 12px 0;
        padding: 0 .8rem;
        top: auto;
      }
      > a:not(.button),
      button,
      .right-off-canvas-toggle {
        &:focus,
        &:hover {
          background-color: $topbar-link-bg-hover;
          color: $white;
        }
      }
      &.active > a:not(.button) {
        background-color: $topbar-link-bg-hover;
        color: $white;
      }
    }
  }
  &-right {
    .mdi {
      color: $white;
      margin-right: 0;
    }
  }
  .menu:not(.dropdown-pane) {
    height: 100%;
  }
  button {
    border-radius: 0;
    cursor: pointer;
    &:not(.button) {
      padding: 0 .5rem;
      &,
      &:hover,
      &[aria-expanded='true'] {
        color: $white;
      }
    }
  }
  &__user {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    white-space: nowrap;
    &-name {
      display: inline-block;
      max-width: 180px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  &__title {
    color: $white;
    display: flex;
    flex: 2;
    float: left;
    margin-right: 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  @include breakpoint(medium) {
    box-shadow: inset 0 -1px 0 0 $top-bar-shadow;
    &__logo {
      img {
        max-width: 130px;
      }
    }
    &__title {
      font-size: rem-calc(map-deep-get($header-styles, 'medium', 'h4', 'font-size'));
      height: 2rem;
      left: 80px;
      line-height: 1.5;
      margin: .4rem 1rem 0 0;
      padding: 0 1.5rem;
      position: fixed;
      top: 0;
    }
    button {
      &:not(.button) {
        &:hover,
        &[aria-expanded='true'] {
          background-color: $topbar-link-bg-hover;
        }
      }
    }
  }

  @include breakpoint(1200px) {
    &__logo {
      padding-left: 1rem;
      padding-right: 1rem;
    }
    &-left > .menu > li > a,
    &-right > .menu > li > a {
      padding: 0 1rem;
    }
    button:not(.button) {
      padding: 0 .7rem;
    }
  }
}

/* stylelint-enable */
