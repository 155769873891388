.subnav {
  .level1 {
    > li {
      margin-bottom: .5rem;
      > a {
        background: $light-bg-color;
        box-shadow: $card-1-shadow;
        color: $header-color;
        font-family: $header-font-family;
        font-weight: $header-font-weight;
        margin: 0;
        padding: .5rem 1rem;
        &::before {
          display: none;
        }
        &:hover,
        &:focus {
          color: $primary-color;
          text-decoration: none;
        }
      }

      > .menu {
        background-color: $white;
        box-shadow: $card-1-shadow;
        padding: 1rem;
      }
    }
    .menu {
      a {
        font-size: 1rem;
        line-height: 1.25;
        padding-left: $subnav-item-padding-left;
        position: relative;
      }
      .menu {
        margin: .75rem 0 1rem;
        li + li {
          margin-top: .25rem;
        }
        a {
          font-size: rem-calc(13);
        }
      }
      &.level2 > li {
        + li {
          margin-top: .75rem;
        }
        > a {
          &::before {
            @include materialiconize('chevron-right', 18);
            left: $subnav-chevron-offset;
            position: absolute;
            top: .45rem;
          }
        }
      }
      .haschildren.open > a {
        color: $header-color;
        &::before {
          @include materialiconize('chevron-down', 18);
        }
      }
    }
    a {
      display: block;
    }
  }
  &-container--minimalistic {
    padding: 0;
    .subnav {
      padding: 0;
      .menu {
        flex-direction: column;
      }
      > .level1 > li > .menu {
        box-shadow: none;
        padding: 0;
      }
      .level1 > li > a {
        background: none;
        box-shadow: none;
        font-size: 1.2rem;
        margin-bottom: 2rem;
        padding: 0;
      }
    }
  }
}

@include breakpoint(medium) {
  .subnav {
    .level1 {
      > li {
        > a {
          font-size: rem-calc(map-deep-get($header-styles, 'medium', 'h2', 'font-size'));
          padding: 1rem 2rem;
        }
        > .menu {
          padding: 2rem;
        }
      }
    }
    &-container--minimalistic {
      border-left: 1px solid $gainsboro;
      margin-top: 4rem;
      padding: 2rem 0 2rem 2rem;
    }
  }
}
