.page--offboarding {
  .knowly {
    float: right;
    margin: 3rem 30vw 0 0;
    width: 30vw;
  }

  .speech-bubble__content {
    .button {
      margin-top: 1rem;
    }

    p {
      margin: 0;
    }

    @include breakpoint(medium) {
      display: flex;

      .button {
        align-self: flex-start;
        margin: 0 0 0 1rem;
        white-space: nowrap;
      }
    }
  }

  @include breakpoint(small only) {
    .speech-bubble::before {
      right: 45vw;
    }
  }
}
