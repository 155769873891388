$knowly-eye-size: 30px;
$knowly-eye-offset: 12px;
$knowly-eye-container-size: (2 * $knowly-eye-size) + $knowly-eye-offset;

.knowly-eyes {
  left: 50%;
  margin: 0 0 0 -60px;
  position: absolute;
  top: 5px;
  width: 120px;
  &__container {
    font-size: 0;
    left: 50%;
    margin-left: -$knowly-eye-container-size * .5;
    position: absolute;
    top: 28px;
    width: $knowly-eye-container-size;
  }
  &__eye {
    background: $white;
    border-radius: 50%;
    display: inline-block;
    height: $knowly-eye-size;
    position: relative;
    transform: rotate(240deg);
    transition: all 200ms ease;
    width: $knowly-eye-size;
    &::after {
      background: $black;
      border-radius: 50%;
      bottom: 17px;
      content: ' ';
      height: 10px;
      position: absolute;
      right: 10px;
      width: 10px;
    }
    + .knowly-eyes__eye {
      margin-left: $knowly-eye-offset;
    }
  }
}
