@use 'sass:color';

$customer-color: color.scale($light-font-color, $lightness: 40%);

.customer {
  align-items: center;
  display: flex;
  float: left;
  justify-content: center;
  padding: 0 10px;
  text-align: center;
  width: 20%;
  &__intro {
    margin: 0 auto 2rem;
  }
  &-list {
    display: flex;
    flex-flow: row nowrap;
    &::before,
    &::after {
      display: none;
    }
  }
  &__image {
    width: 140px;
  }
}

@include breakpoint(small only) {
  .customer {
    width: 33.3%;
  }
}
