@include breakpoint(medium) {
  .page--about-us {
    .jumbotron {
      &.breakout {
        .jumbotron__section > .row > .column {
          &:first-child {
            align-items: center;
            justify-content: center;
          }
          &:last-child {
            justify-content: flex-start;
          }
        }
      }
    }
    .illustration {
      &--to-the-top {
        margin-bottom: -5rem;
      }
      &--team {
        margin-bottom: -5rem;
        max-width: 430px;
      }
      &--egg {
        max-width: 430px;
      }
    }
  }
}

@include breakpoint(small only) {
  .page--about-us {
    .jumbotron.breakout {
      .jumbotron__section > .row > .column:first-child {
        text-align: center;
      }
      .illustration {
        display: inline-block;
        margin: 2rem 0;
        width: 80%;
      }
    }
  }
}
