// Mixin for eyes keyframes, since transforming with percentage doesn't work in SVG's:
// Percentage is always relative to the viewBox
/* stylelint-disable block-closing-brace-newline-after */
/*! rtl:begin:ignore */
@mixin keyframes-animation-eyes($offset, $direction) {
  0% {
    transform: translateX(0);
  }
  1% {
    @if $direction == 'left' {
      transform: translateX($offset);
    } @else {
      transform: translateX(-$offset);
    }
  }
  70% {
    @if $direction == 'left' {
      transform: translateX($offset);
    } @else {
      transform: translateX(-$offset);
    }
  }
  71% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(0);
  }
}

/* stylelint-enable block-closing-brace-newline-after */

@keyframes animation-team-eyes-looking-left {
  @include keyframes-animation-eyes(13px, 'left');
}

@keyframes animation-team-eyes-looking-right {
  @include keyframes-animation-eyes(13px, 'right');
}

@keyframes animation-colleagues-eyes-looking-left {
  @include keyframes-animation-eyes(8px, 'left');
}

@keyframes animation-colleagues-eyes-looking-right {
  @include keyframes-animation-eyes(8px, 'right');
}

@keyframes animation-vacancy-eyes-looking-right {
  @include keyframes-animation-eyes(12px, 'right');
}

@keyframes animation-generic-vacancy-eyes-looking-right {
  @include keyframes-animation-eyes(3px, 'right');
}

@keyframes animation-homepage-knowly-employee-eyes-looking-left {
  @include keyframes-animation-eyes(13px, 'left');
}

/*! rtl:end:ignore */
