/*! rtl:begin:ignore */
.illustration--homepage {
  &__thought-cloud {
    > g {
      animation: animation-fade-out linear .3s forwards;
      animation-delay: 3.75s;
    }
    &-cloud,
    &-question-mark,
    &-dot-s,
    &-dot-xs {
      animation: animation-fade-in linear .3s forwards;
      opacity: 0;
    }
    &-dot-xs {
      animation-delay: 1s;
    }
    &-dot-s {
      animation-delay: 1.33s;
    }
    &-cloud {
      animation-delay: 1.66s;
    }
    &-question-mark {
      animation-delay: 1.75s;
    }
  }
  &__knowly-employee {
    &-thinking-wing {
      animation: animation-fade-out linear .3s forwards;
      animation-delay: 5.5s;
    }
    &-pointing-wing {
      animation: animation-fade-in linear .3s forwards;
      animation-delay: 5.5s;
      opacity: 0;
    }
    &-eyes {
      animation: animation-homepage-knowly-employee-eyes-looking-left 10s;
      animation-delay: 2.5s;
    }
    &-nose {
      animation: animation-shaking-nose .5s;
      animation-delay: 7s;
    }
  }
  &__knowly-employer > g {
    animation: animation-fade-in-left ease-in-out 1s forwards;
    animation-delay: 3s;
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
    > g {
      animation: animation-flying-knowly ease-in-out 1s infinite;
      animation-delay: 3.5s;
    }
  }
  &__knowly-employer-xmas-hat {
    visibility: hidden;
  }
  &__interface > g {
    animation: animation-fade-in-down linear 1s forwards;
    animation-delay: 3.5s;
    animation-fill-mode: both;
    animation-timing-function: ease-in-out;
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  &__cap > g {
    animation: animation-fade-in-down linear .66s forwards;
    animation-delay: 5.75s;
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  &__certificate > g {
    animation: animation-slide-in-down linear .66s forwards;
    animation-delay: 6.25s;
    transform: translate3d(0, -100%, 0);
    visibility: hidden;
  }
}

/*! rtl:end:ignore */
