@mixin label-icon-style($color) {
  background-color: $color;
  border-radius: 9999px;
  color: $white;
  display: inline-block;
  padding: 5px;
}

@mixin label-icon-size($size) {
  font-size: $size;
  height: $size + 10px;
  width: $size + 10px;
  i {
    display: inline-block;
    font-size: 1em;
    height: $size;
    width: $size;
    &::before {
      height: $size;
      line-height: $size;
      position: absolute;
      width: $size;
    }
  }
}

.label-icon {
  @include label-icon-style($base);

  // Color styles
  &--primary { @include label-icon-style($primary-color); }
  &--secondary { @include label-icon-style($secondary-color); }
  &--alert { @include label-icon-style($alert-color); }
  &--success { @include label-icon-style($success-color); }
  &--warning { @include label-icon-style($warning-color); }
  &--info { @include label-icon-style($info-color); }

  // Same size as included mdi-icon
  &--18 { @include label-icon-size(18px); }
  &--24 { @include label-icon-size(24px); }
  &--36 { @include label-icon-size(36px); }
  &--48 { @include label-icon-size(48px); }

  // To put a number insyde a label icon
  &--number {
    font-weight: bold;
    line-height: 1em;
    text-align: center;
  }
}

@include breakpoint(small only) {
  .label-icon {
    transform: scale(.8);
  }
}
