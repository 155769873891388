$star-color: #f7bc07;

.star-rating {
  display: block;
  margin: 0;
  white-space: nowrap;
  &__item {
    color: $base;
    display: inline-block;
    line-height: 1;
    list-style: none;
    position: relative;
    &,
    .mdi {
      height: 24px;
      width: 24px;
    }
    .mdi {
      left: 0;
      position: absolute;
    }
    &--active * {
      color: $star-color;
    }

    &--half {
      .mdi {
        &:first-child {
          color: $star-color;
          width: 12px;
        }
        + .mdi {
          left: auto;
          overflow: hidden;
          right: 0;
          text-indent: -6px;
          width: 12px;
          z-index: 2;
        }
      }
    }
  }
}
