$cloud-background: #ffffff;

.clouds {
  pointer-events: none;
}

/*! rtl:begin:ignore */
// moving clouds
@include breakpoint(medium) {
  .clouds {
    /* stylelint-disable-next-line declaration-no-important */
    display: block !important;
    overflow: hidden;
    padding: 100px 0;
    position: absolute;
    width: 100%;
    z-index: 0;
  }
  .cloud {
    animation-iteration-count: infinite;
    animation-name: moveclouds;
    animation-timing-function: linear;
    background: $cloud-background;
    border-radius: 200px;
    height: 60px;
    position: relative;
    width: 200px;
    &::before,
    &::after {
      background: $cloud-background;
      border-radius: 100px;
      content: '';
      height: 80px;
      left: 10px;
      position: absolute;
      top: -15px;
      transform: rotate(30deg);
      width: 100px;
    }
    &::after {
      height: 120px;
      left: auto;
      right: 15px;
      top: -55px;
      width: 120px;
    }
  }

  .x1 {
    animation-duration: 15s;
    opacity: .5;
  }

  .x2 {
    animation-duration: 25s;
    left: 400px;
    opacity: .3;
    transform: scale(.6);
  }

  .x3 {
    animation-duration: 20s;
    left: 550px;
    opacity: .4;
    top: -200px;
    transform: scale(.8);
  }

  .x4 {
    animation-duration: 18s;
    left: 970px;
    opacity: .6;
    top: -250px;
    transform: scale(.75);
  }

  .x5 {
    animation-duration: 20s;
    left: 950px;
    opacity: .7;
    top: -150px;
    transform: scale(.8);
  }
}

@keyframes moveclouds {
  0% {
    margin-left: 1000px;
  }
  100% {
    margin-left: -1000px;
  }
}

/*! rtl:end:ignore */
