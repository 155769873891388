$blockquote-bg-color: $white-smoke;
$blockquote-inner-offset: 80px;

.blockquote {
  margin: 2rem 0;
  position: relative;
  text-align: left;
  &::after {
    @include materialiconize('format-quote-open', 48px);
    color: $primary-color;
    left: 4rem;
    position: absolute;
    top: -1rem;
  }
  &__text {
    background: $blockquote-bg-color;
    color: $header-color;
    margin: 0 0 1rem;
    padding: 2rem;
    position: relative;
    text-align: left;
    &::before {
      border-color: $blockquote-bg-color transparent transparent transparent;
      border-style: solid;
      border-width: 20px 20px 0;
      bottom: -20px;
      content: '';
      height: 0;
      left: $blockquote-inner-offset;
      position: absolute;
      width: 0;
    }
    p {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &__cite {
    font-style: normal;
    min-height: 80px;
    padding-top: 1rem;
    &::before {
      display: none;
    }
  }
  &--up {
    .blockquote__text::before {
      top: -20px;
      transform: scaleY(-1);
    }
  }
  &--right {
    .blockquote__text::before {
      left: auto;
      right: $blockquote-inner-offset;
    }
  }
  &--side {
    padding-left: 0;
    padding-right: 0;
  }
  &--large {
    .blockquote__text {
      font-size: 1.1rem;
    }
  }
  + .blockquote {
    &__cite {
      float: right;
    }
  }
  .card__body & {
    padding-left: 0;
    padding-right: 0;
  }

  @media #{$medium-up} {
    &--side {
      margin: 0 0 4rem -4rem;
      padding-left: 0;
      padding-right: 0;
      &:last-child {
        margin-bottom: 0;
      }
      .subnav-container + & {
        margin-top: 6rem;
      }
    }
  }
}
