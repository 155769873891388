$right-dropdown-padding: 2.5rem;
$dropdown-arrow-offset: .75rem;

/* stylelint-disable declaration-no-important */
.dropdown {
  overflow: hidden;
  &-pane {
    display: none;
    text-align: left;
    width: auto;
    &.menu,
    .menu {
      a,
      button:not(.button) {
        display: block;
        font-weight: $global-weight-normal;
        line-height: 1.5;
        padding: $global-menu-padding;
        text-align: inherit;
        white-space: nowrap;
        width: 100%;
      }
    }
    &.is-open.menu > li {
      display: block;
    }
  }
}

.button.dropdown,
.has-dropdown,
.button--dropdown {
  display: inline-block;
  padding-right: $right-dropdown-padding !important;
  position: relative;
  &::after {
    $icon-size: 18px;

    @include materialiconize('menu-down', $icon-size);
    border: 0 transparent !important;
    color: $white;
    cursor: pointer;
    margin: -($icon-size * .5) 0 0 !important;
    position: absolute;
    right: $dropdown-arrow-offset !important;
    top: 50%;
    vertical-align: middle;
    width: $icon-size;
  }
}

// grid-view dropdown
.column--button .button-group > li:last-child .f-dropdown {
  left: auto !important;
  right: 0 !important;
  width: auto !important;
}

/* stylelint-enable */
