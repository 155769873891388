@use 'sass:color';

$jumbotron-questionmarks-offset: -10%;

.jumbotron {
  align-items: center;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  clear: both;
  display: flex;
  flex: 1 1 auto;
  flex-flow: column wrap;
  justify-content: center;
  overflow: hidden;
  padding: 0;
  position: relative;
  &__title {
    color: $header-color;
    font-size: 2.4rem;
  }
  &__section {
    display: flex;
    flex: 1 1 auto;
    flex-flow: column wrap;
    z-index: 2;
  }
  &,
  &__section,
  .row {
    width: 100%;
  }
  &__matrix-bg {
    background: color.scale($secondary-color, $lightness: -25%);
    bottom: 0;
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 1;
  }
  .intro {
    font-size: 1.2rem;
  }
  p {
    max-width: none;
  }
  .knowly {
    clear: both;
  }
  .button-group {
    .button {
      margin-bottom: 1rem;
    }
  }
  .clouds {
    bottom: 0;
    opacity: .2;
    top: 0;
  }
  &--autoheight {
    min-height: 0;
  }
  &-container {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  &--collapse {
    min-height: 0;
    padding-bottom: 1rem;
    transform: none;
  }
  &--vh {
    min-height: calc(100vh - #{$topbar-height});
  }
  &--image {
    &::after {
      $image-bg-start: color.scale($secondary-color, $lightness: -20%);
      $image-bg-end: color.scale($secondary-color, $lightness: -60%);
      background-image: linear-gradient(to bottom, $image-bg-start, $image-bg-end);
      bottom: 0;
      content: '';
      display: block;
      left: 0;
      opacity: .85;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 1;
    }
  }
  &--primary {
    background-color: $secondary-color;
    .jumbotron__title,
    [class*='heading--h'] {
      &,
      &[class*='heading--h'] {
        color: $white;
      }
    }
    .intro {
      color: $white;
    }
    .jumbotron__section {
      a:not(.button) {
        color: $white;
        text-decoration: underline;
        &:hover,
        &:focus {
          color: color.scale($white, $lightness: -15%);
        }
      }
    }
  }
  &--light,
  &--medium {
    background-color: $white;
    .intro {
      color: $body-font-color;
    }
    .heading--serif {
      color: $primary-color;
    }
  }
  &--medium {
    background-color: $body-background;
    .intro a {
      color: color.scale($secondary-color, $lightness: -10%);
      &:hover,
      &:focus {
        color: color.scale($secondary-color, $lightness: -25%);
      }
    }
    .heading--serif {
      color: $secondary-color;
    }
  }
}

@include breakpoint(small only) {
  .jumbotron--primary {
    border-top: 1px solid rgba($white, .2);
  }
}

@include breakpoint(medium) {
  .jumbotron {
    &:not(.jumbotron--collapse, .jumbotron--autoheight, .jumbotron--small, .jumbotron--vh) {
      min-height: 60vh;
    }
    &--small {
      min-height: 300px;
    }
    &__section {
      display: flex;
      flex: 1 1 auto;
      flex-direction: column;
      > .row {
        display: flex;
        flex: 1 1 auto;
        flex-flow: row nowrap;
        > .column {
          display: flex;
          flex-direction: column;
          &:last-child {
            justify-content: flex-end;
          }
          &:first-child {
            justify-content: center;
          }
          .button {
            align-self: flex-start;
          }
        }
      }
    }
    .intro {
      font-size: 1.4rem;
    }
    &__title {
      font-size: 3rem;
      line-height: 1.2;
    }
    form {
      max-width: 540px;
    }
    .input-group__input input[type='text'],
    .input-group__input input[type='email'] {
      height: 3.8rem;
    }
    .input-group__input input,
    .input-group__button .button,
    .button.large {
      font-size: 1.4rem;
    }
    .button-group .button {
      margin-left: 0;
      margin-right: .5rem;
      &:last-child {
        margin-right: 0;
      }
    }

    // illustrations
    .questionmarks {
      max-width: 68%;
      opacity: .4;
      position: absolute;
      right: $jumbotron-questionmarks-offset;
      top: 2rem;
    }
    .speech-bubble.left {
      margin-left: 20%;
      position: relative;
      transform: rotate(-5deg);
    }
    .knowly {
      max-width: 80%;
    }
  }
}
