@use 'sass:map';

/* stylelint-disable block-closing-brace-newline-after */
/*! rtl:begin:ignore */
@mixin keyframes-animation-shaking-nose($sensitivity) {
  $factors: (
    positive: 10% * $sensitivity,
    negative: -10% * $sensitivity,
  );

  0% {
    transform: translate(0, 0);
  }
  12.5% {
    transform: translate(map.get($factors, 'positive'), 0);
  }
  25% {
    transform: translate(0, 0);
  }
  37.5% {
    transform: translate(map.get($factors, 'negative'), 0);
  }
  50% {
    transform: translate(0, 0);
  }
  62.5% {
    transform: translate(map.get($factors, 'positive'), 0);
  }
  75% {
    transform: translate(0, 0);
  }
  87.5% {
    transform: translate(map.get($factors, 'negative'), 0);
  }
  100% {
    transform: translate(0, 0);
  }
}

/* stylelint-enable block-closing-brace-newline-after */

@keyframes animation-shaking-nose {
  @include keyframes-animation-shaking-nose(.03);
}

@keyframes animation-shaking-nose-feature {
  @include keyframes-animation-shaking-nose(.8);
}

/*! rtl:end:ignore */
