.articlenav {
  background-color: $white;
  &__imagelink {
    display: block;
    margin-bottom: 1rem;
    img {
      width: 100%;
    }
  }

  @include breakpoint(medium) {
    .row {
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
    }
  }
}
