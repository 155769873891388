/*! rtl:begin:ignore */

@keyframes animation-sunglasses-sparkle {
  0% {
    transform: scale(0) rotate(0deg);
  }
  5% {
    transform: scale(3) rotate(180deg);
  }
  10% {
    transform: scale(0) rotate(360deg);
  }
  100% {
    transform: scale(0) rotate(360deg);
  }
}

/*! rtl:end:ignore */
