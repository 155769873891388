/*! rtl:begin:ignore */
@keyframes animation-fade-in-left {
  0% {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

/*! rtl:end:ignore */
