@use 'sass:color';

.switch {
  float: left;
  label {
    font-size: 0;
    &::before {
      background: $medium-gray;
      border-radius: 50%;
      content: '';
      display: block;
      height: 3rem;
      left: 25%;
      opacity: 0;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      transition: all .25s ease-out;
      width: 3rem;
      z-index: 0;
    }
    &::after {
      z-index: 3;
    }
  }
  input[type='radio'],
  input[type='checkbox'] {
    position: absolute;
    &:focus ~ label::before {
      opacity: .5;
    }
    &:checked ~ label::before {
      background: $primary-color;
      left: 75%;
    }
  }

  &-label {
    display: inline-block;
    font-weight: $global-weight-normal;
    padding: 0 .5rem;
  }

  &--on-secondary {
    .switch-paddle,
    input:checked ~ label.switch-paddle {
      background-color: color.scale($secondary-color, $lightness: -20%);
      &::before {
        background-color: $white;
      }
    }
    .switch-paddle::after {
      opacity: .8;
    }
  }
}

.formcontrol--switch {
  align-items: center;
  display: flex;
  .formcontrol__switch-label {
    background: none;
    height: auto;
    width: auto;
    &::after {
      display: none;
    }
  }
  &.formcontrol--inline {
    .formcontrol__inner {
      align-items: center;
      display: flex;
      .formcontrol__switch-label {
        display: inline-block;
        margin-right: 1rem;
      }
    }
    .switch {
      float: none;
      line-height: 0;
    }
    .switch-paddle {
      top: -5px;
    }
  }
}
