.css-columns {
  > * {
    display: inline-block;
    width: 100%;
  }
  &--3 {
    column-count: 3;
  }

  @include breakpoint(medium) {
    &--medium-2 {
      column-count: 2;
    }
  }

  @include breakpoint(large) {
    &--large-3 {
      column-count: 3;
    }
  }
}
