.team {
  &__photo {
    margin: 0 0 1rem;
    img {
      border-radius: 9999px;
    }
  }
  &__name {
    margin-bottom: $header-margin-bottom * .5;
  }
  &__jobtitle {
    color: $header-color;
    font-size: $global-font-size;
    font-style: italic;
    font-weight: $global-weight-normal;
    margin-bottom: $header-margin-bottom * 2;
  }
  &__contact-button {
    align-self: center;
    flex: 0 0 auto;
    margin: auto 0 .25rem;
  }
  &__language {
    color: $jumbo;
    font-size: $small-font-size;
  }
  &__description {
    margin-bottom: .75rem;
    &:last-child {
      margin-bottom: 0;
    }
    &,
    * {
      font-size: $global-font-size;
    }
    .icon {
      margin: -.5rem 0;
    }
  }
}
